


import store from './../store/index'

let langs =
  [
  {
    key: '用户名',
    value: 'Username',
  },
  {
    key: '请输入新密码',
    value: 'Please enter the new password ',
  },
  {
    key: '请输入完整邮箱',
    value: 'Please enter the complete email address.',
  },
  {
    key: '如果您想购买或者是需要拿样品，请与我们的工作人员联系',
    value:
      'If you want to purchase this product or take a sample, please contact our agent.',
  },
  {
    key: '预计进口产品费用明细 ',
    value: 'Estimated product import details',
  },
  {
    key: '*如果手机号码输入错误，将无法发送验证码',
    value:
      '*Ensure the number matches your account for verification.',
  },
  {
    key: '*如果邮箱输入错误，将无法发送验证码',
    value:
      "*Can't send verification code due to email mismatch",
  },
  {
    key: '请将您的个人信息填入下方',
    value: 'Please fill in the following fields with your information',
  },
  {
    key: '银行转账',
    value: 'Bank transfer',
  },
  {
    key: '将您订单中需花费的钱转账到我们账户',
    value: 'Transfer the money required for your order to our account',
  },
  {
    key: '等待1至2个工作日处理订单.',
    value: 'Waiting for 1-2 working days',
  },
  {
    key: '是否确认提交汇款?',
    value: 'Are you sure to submit payment?',
  },
  {
    key: '物流说明',
    value: 'Shipping Information',
  },
  {
    key:
      '使用以下银行数据通过您的在线银行或移动应用程序进行转账，订单处理后，将进行购买。',
    value:
      'Transfer funds through your online banking or mobile application using the following bank data. After the order is processed, the purchase will be made',
  },
  {
    key: '请上传付款证明',
    value: 'Please upload payment proof',
  },
  {
    key: '请填写备注信息',
    value: 'Please fill in the remarks',
  },
  {
    key: '请输入付款金额',
    value: 'Please enter the payment amount',
  },
  {
    key: '请填写转账金额',
    value: 'Please fill in the transfer amount',
  },
  {
    key: '请填写付款人姓名',
    value: 'Please fill in the name of the payer',
  },
  {
    key: '手机号',
    value: 'Cellphone number',
  },
  {
    key: '手机号不能为空',
    value: 'Cellphone phone number cannot be empty',
  },
  {
    key: 'SSN(选填)',
    value: 'SSN(optional)',
    },
    {
      key: "选填",
      value:'optional',
    },
    {
      key: '请输入6-请输入6-16位包含大小写字母和数字的密码',
      value: "Please enter a password containing 6-16 characters",
      
  },
  {
    key: '收件人银行信息',
    value: "Recipient's Bank Information",
  },
  {
    key: '请选择日期',
    value: ' Please select a date',
  },
  {
    key: '使用订单号作为付款概念/参考',
    value: 'Use order number as payment concept/reference',
  },
  {
    key: '它是如何运作的?',
    value: 'How does it work?',
  },
  {
    key: '收款人名称',
    value: 'Name of payee',
  },
  {
    key: '提交人',
    value: 'Submitter',
  },
  {
    key: '请输入提交人',
    value: 'Please enter the submitter',
  },
  {
    key: '请输入付款日期',
    value: 'Please enter the payment date',
  },
  {
    key: '付款金额',
    value: 'Payment amount',
  },
  {
    key: '付款截图',
    value: 'Payment screenshot',
  },
  {
    key: '确认转账',
    value: 'Confirm transfer',
  },
  {
    key: '单价',
    value: 'Unit price',
  },
  {
    key: '预估美国国内运费',
    value: 'Estimated domestic freight in the United States',
  },
  {
    key: '包含运费产品总成本',
    value: 'Total product cost including freight',
  },
  {
    key: '密码',
    value: 'Password',
  },
  {
    key: '当前商品',
    value: 'Current product',
  },
  {
    key: '忘记密码',
    value: 'Forgot password',
  },
  {
    key: '写邮件与我们沟通',
    value: 'Write an email to service@rakumart.us',
  },
  {
    key: '请输入关键词或通过商品图片搜索',
    value: 'Please enter keywords or search through product image',
  },
  {
    key: '添加收藏成功',
    value: 'Favorite added successfully',
  },
  {
    key: '银行支付',
    value: 'Bank payment',
    },
    {
      key: '银行地址',
      value:'Bank Address',
    },
    {
      key: "账号名称",
  value:'Account Name',    
    },
  
  {
    key: '取消收藏成功',
    value: 'Favorite removed successfully',
  },
  {
    key: '请填写收件人',
    value: 'Please fill in the recipient',
  },
  {
    key: '请勿传入空值',
    value: 'Do not enter a null value',
  },
  {
    key: '请输入城市',
    value: '  Please enter the city ',
  },
  {
    key: '请输入收货地址',
    value: 'Please enter the shipping address',
  },
  {
    key: '不能为空',
    value: 'Cannot be empty',
  },
  {
    key: '新增地址',
    value: 'The new address',
  },
  {
    key: '产品数量',
    value: 'Product quantity',
  },
  {
    key: '邮箱验证码',
    value: 'Email verification code',
  },
  {
    key: '请输入邮箱验证码',
    value: 'Please enter the email verification code.',
  },
  {
    key: '产品详情',
    value: 'Replacement product',
  },
  {
    key: '替换产品',
    value: 'Replacement product',
  },
  {
    key: '咨询',
    value: 'counsel',
  },
  {
    key: '谷歌登录',
    value: 'Sign in with Google',
  },
  {
    key: '用户',
    value: 'User',
  },
  {
    key: '手机',
    value: 'Cell phone',
  },
  {
    key: '谢谢，你的订单号',
    value: 'Thank you. Your order number ',
  },
  {
    key:
      '已生成正确，并且已经在发送到我们仓库的过程中。 我们已经向您发送了一封包含详细信息的电子邮件。请按照个人中心中的状态进行操作',
    value:
      ' has been generated correctly and is in the process of sending to our warehouse. We have sent you an email with details. Please follow the status in the personal center.',
  },
  {
    key: '最低入金金额',
    value: 'Minimum deposit amount',
  },
  {
    key: '订单应付款金额',
    value: 'Order payable amount',
  },
  {
    key: '加入商品库',
    value: 'Add to product lists',
  },
  {
    key: '记住密码',
    value: '记住密码',
  },
  {
    key: '请输入账号',
    value: 'Please enter account number',
  },
  {
    key: '请输入密码',
    value: 'Please enter your password',
  },
  {
    key: '请选择认证方式',
    value: 'Please select the authentication method',
  },
  {
    key: '请输入你的账户名称',
    value: 'Please enter your account name',
  },
  {
    key: '手机号码',
    value: 'Mobile phone number',
  },
  {
    key: '验证码获取',
    value: 'Get verification code',
  },
  {
    key: '城市名',
    value: 'City name',
  },
  {
    key: '帐号注册完成',
    value: 'Account registration is complete',
  },
  {
    key: '非常感谢您的注册',
    value: 'thank you very much for your registration',
  },
  {
    key: '国家名',
    value: 'State name',
  },
  {
    key: '填写个人信息',
    value: 'Fill in personal information',
  },
  {
    key: '个人信息确认',
    value: 'Confirm personal information',
  },
  {
    key: '完成注册',
    value: 'Complete registration',
  },
  {
    key: '账户注册（需要信息）',
    value: 'Account Registration (Information Required)',
  },
  {
    key: '姓氏',
    value: 'Last name',
  },
  {
    key: '请输入姓氏',
    value: 'Please enter your last name',
  },
  {
    key: '名字',
    value: 'First name',
  },
  {
    key: '请输入名字',
    value: 'Please enter your first name',
  },
  {
    key: '密码输入不一致',
    value: 'Inconsistent password entered',
  },
  {
    key: '请输入正确的邮箱地址',
    value: 'Please enter the correct email address',
  },
  {
    key: '电话',
    value: 'Telephone',
  },
  {
    key: '请输入您的手机号',
    value: 'Please enter your cellphone number',
  },
  {
    key: '请输入NIF',
    value: 'Introduce NIF',
  },
  {
    key: '发送验证码',
    value: 'Send verification code',
  },
  {
    key: '密码请控制在6位以上16位以内',
    value:
      'Password should contain a minimum of 6 characters and a maximum of 16 characters.',
  },
  {
    key: '注册完成',
    value: 'Registration complete',
  },
  {
    key: '账号注册完成，非常感谢您的注册',
    value:
      'Account registration is complete Thank you very much for your registration',
  },
  {
    key: '去登录',
    value: 'Go to login',
  },
  {
    key: '关键词',
    value: 'Keywords',
  },
  {
    key: '信息完善',
    value: 'Perfect information',
  },
  {
    key: '已取消订单',
    value: 'Closed',
  },
  {
    key: '请您填写以下信息，以便完成注册',
    value:
      'Please fill in the following information in order to complete the registration',
  },
  {
    key: '搜索结果有问题？',
    value: 'Having trouble with your search results?',
  },
  {
    key: '没有找到商品，请换一张图片试试',
    value: 'No product found, please try another image',
  },
  {
    key: '请输入正确的手机号码',
    value: 'Please enter the complete mobile phone number.',
  },
  {
    key: '注册信息',
    value: 'Registration message',
  },
  {
    key: '登录失效',
    value: 'Logon failure',
  },
  {
    key: '个人中心',
    value: 'Personal center',
  },
  {
    key: '意见反馈',
    value: 'feedback',
  },
  {
    key: '退出登录',
    value: 'Log out',
  },
  {
    key: '登录',
    value: 'Sign in',
  },
  {
    key: '注册',
    value: 'Register',
  },
  {
    key: '购物车',
    value: 'Cart',
  },
  {
    key: '提现金额必须大于0',
    value: 'The withdrawal amount must be greater than 0',
    },
    {
      key: '提现金额不能小于0',
      value:'The withdrawal amount cannot be less than 0',
  },
  {
    key: '感谢您的下单，您的订单已经成功提出。',
    value: 'Thank you. Your order details have been confirmed correctly.',
  },
  {
    key: '正在进行的报价',
    value: 'Ongoing quotation',
  },
  {
    key: '为您推荐',
    value: 'Recommended for you',
  },
  {
    key: '新手导航',
    value: 'The novice navigation',
  },
  {
    key: '关于RAKUMART',
    value: 'About us',
  },
  {
    key: '佣金比例',
    value: 'Commission fee ratio',
  },
  {
    key: '国际运费说明',
    value: 'International Freight description',
  },
  {
    key: '已驳回',
    value: 'Rejected',
  },
  {
    key: '查看相似',
    value: 'Check the similar',
  },
  {
    key: '所有类别',
    value: 'All categories',
  },
  {
    key: '查看更多推荐',
    value: 'See more recommendations',
  },
  {
    key: '已售',
    value: 'Sold',
  },
  {
    key: '关于费用',
    value: 'About fees',
  },
  {
    key: '首页',
    value: 'Home page',
  },
  {
    key: '请输入新手机号',
    value: 'Please enter a new phone number',
  },
  {
    key: '新手机号码',
    value: 'New phone',
  },
  {
    key: '加入购物车',
    value: 'Add to Shopping Cart',
  },
  {
    key: '总计',
    value: 'In Total',
  },
  {
    key: '邮编输入错误',
    value: 'Incorrect postcode entry',
  },
  {
    key: '在库数',
    value: 'Supplier stock',
    },
    {
      key: '装箱单状态',
      value: 'Packinglist status',
    },
  {
    key: '购物车添加成功',
    value: 'Shopping cart added successfully',
  },
  {
    key: '移除收藏夹成功',
    value: 'Favorite removed successfully',
  },
  {
    key: '收藏店铺成功',
    value: 'Favorite added successfully',
  },
  {
    key: '商家推荐',
    value: 'Store Popular Items',
  },
  {
    key: '收藏店铺',
    value: 'Add the store to your favourites',
  },
  {
    key: '保持登录状态',
    value: 'Keep me logged in',
  },
  {
    key: '电子邮箱',
    value: 'Email address',
  },
  {
    key: '请输入邮箱/手机号',
    value: 'Please enter correct email address/mobile number',
  },
  {
    key: '新会员注册',
    value: 'New member registration',
  },
  {
    key: '账户名或者密码输入错误',
    value: 'The account name or password is incorrect',
  },
  {
    key: '国家',
    value: 'countries',
  },
  {
    key: '登录名',
    value: 'Login name',
  },
  {
    key: '密码确认',
    value: 'The password confirmation',
  },
  {
    key: '验证码',
    value: 'Verification code',
  },

  {
    key: '运输到仓库',
    value: 'Shipment to warehouse',
  },
  {
    key: 'SSN输入错误',
    value: 'Incorrect SSN input',
  },
  {
    key: 'NIF / DNI',
    value: 'NIF/DNI',
  },
  {
    key: '请输入新的密码',
    value: 'Please enter the new password ',
  },
  {
    key: '请再次输入密码',
    value: '  Please enter the password again',
  },
  {
    key: '入库完了',
    value: 'In stock',
  },
  {
    key: '请输入SSN',
    value: 'Please enter SSN',
  },
  {
    key: '资金变动明细',
    value: 'Details of changes in funds',
  },
  {
    key: 'RAKUMART',
    value: 'RAKUMART',
  },
  {
    key: 'RAKUMART从事于批发进口，不提供任何零售服务',
    value:
      'Rakumart is a wholesale import platform and does not provide retail services',
  },
  {
    key: '联系工作人员',
    value: 'Talk to the agent',
  },
  {
    key: 'RAKUMART的用户协议和隐私政策',
    value: 'RAKUMART user agreement and privacy policy',
  },
  {
    key: '的用户协议',
    value: 'user agreement',
  },
  {
    key: '的用户协议和隐私政策',
    value: 'user agreement and privacy policy',
  },
  {
    key: '待入金',
    value: 'Pending deposit',
  },
  {
    key: '提现中',
    value: 'Withdrawing',
  },
  {
    key: '上架完了',
    value: 'In Stock',
  },
  {
    key: 'and',
    value: ' &',
  },
  {
    key: '新电子邮箱',
    value: 'New email',
  },
  {
    key: '商品标题',
    value: '',
  },

  {
    key: '同意以上协议，进入下一步',
    value: 'Agree to the above agreement, go to the next step',
  },
  {
    key: '如何注册/登录？',
    value: 'How to register/log in?',
  },
  {
    key: '请输入新的电子邮箱',
    value: 'Please enter a new email',
  },
  {
    key: '已被使用',
    value: 'Has been used',
  },
  {
    key: '验证码错误',
    value: 'Verification code error',
  },
  {
    key: '没有找到商品，请换一个关键词试试',
    value: 'No product found, please try another keyword',
  },
  {
    key: '商品关键词或阿里巴巴、淘宝商品URL',
    value: 'Product keywords or Alibaba, Taobao product URL',
  },
  {
    key: '商品替换',
    value: 'Replacement of goods',
  },
  {
    key: '常见问题',
    value: 'Frequently Asked Questions',
  },
  {
    key: ' 常见问题',
    value: 'Frequently asked questions',
  },
  {
    key:
      '我们根据订单总额收取佣金。对于每个金额，都有特定百分比的佣金。请参阅下表，了解您的订单将对应多少百分比。',
    value:
      'We charge a commission on the total order. For each amount, there is a specified percentage commission. See the table below to see what percentage your order will correspond to.',
  },
  {
    key: '采购商品金额（美元）',
    value: 'Amount of goods purchased (buck)',
  },
  {
    key: 'option说明界面',
    value: 'Option Description',
  },
  {
    key: '件',
    value: 'Item',
  },
  {
    key: '会员服务',
    value: 'Membership service',
  },
  {
    key: '会员等级',
    value: 'Membership level',
  },
  {
    key: '附加服务说明',
    value: 'Additional service description',
  },
  {
    key: '店铺信息',
    value: 'Store Information',
  },
  {
    key: '收藏商品',
    value: 'Favorite products',
  },
  {
    key: '进入店铺',
    value: 'Enter the shop',
  },
  {
    key: '移出收藏夹',
    value: 'Remove from favourites',
  },
  {
    key: '商品详情',
    value: 'Goods details',
  },
  {
    key: '商品推荐',
    value: 'Recommended Products',
  },
  {
    key: '单价',
    value: 'Unit price',
  },
  {
    key: '60',
    value: 'Unit price',
  },
  {
    key: '价格',
    value: 'Unit price',
  },
  {
    key: '修改州',
    value: 'Change the state',
  },
  {
    key: '数量',
    value: 'Quantity',
  },
  {
    key: '运费',
    value: 'freight',
  },
  {
    key: '暂无此规格',
    value: 'This specification is not available at present',
  },
  {
    key: '60秒内已发送过一次验证码',
    value: "It's been sent once in 60 seconds",
  },
  {
    key: '发送短信失败',
    value: 'Sending Short Messages Fails',
  },
  {
    key: '结算',
    value: 'Check out',
  },
  {
    key: '确认删除该商品吗？',
    value: 'Whether to remove this item from the shopping cart？',
  },
  {
    key: '确认',
    value: 'Confirm',
  },
  {
    key: '订单金额',
    value: 'Order amount',
  },
  {
    key: '缺少必填项',
    value: 'Required fields are missing',
  },
  {
    key: '您目前没有优惠券',
    value: "You don't have any coupons at the moment",
  },
  {
    key: '请选择优惠券',
    value: 'Please select the coupon',
  },
  {
    key: '是否将选中的商品移除购物车?',
    value: 'Whether to remove the selected item from the shopping cart？',
  },
  {
    key: '未选择操作的商品',
    value: 'Items with no action selected',
  },
  {
    key: '删除成功',
    value: 'Delete successfully',
  },
  {
    key: '购物车商品删除成功',
    value: 'Items successfully removed from the shopping trolley',
  },
  {
    key: '保存成功',
    value: 'Saved successfully',
  },
  {
    key: '运输中配送单',
    value: 'In Transit',
  },
  {
    key: '去购物',
    value: 'Go shopping',
  },
  {
    key: '人气商品',
    value: 'Popular products',
  },
  {
    key: '更改头像',
    value: 'Change profile picture',
  },
  {
    key: '头像上传中',
    value: 'Profile picture uploading',
  },
  {
    key: '张',
    value: 'sheet',
  },
  {
    key: '重新加载',
    value: 'Reload',
  },

  {
    key: '佣金',
    value: 'Commission ',
  },
  {
    key: '重量',
    value: 'Weight ',
  },
  {
    key: '未提出订单',
    value: 'No order placed',
  },
  {
    key: '提出时间',
    value: 'Proposed Time',
  },
  {
    key: '待发货配送单',
    value: 'Awaiting shipping',
  },
  {
    key: '运输中',
    value: 'In transit',
  },
  {
    key: '运费报价中',
    value: 'Freight is in quotation',
  },
  {
    key: '运费待付款',
    value: 'Freight to be paid',
  },
  {
    key: '国际派送中',
    value: 'International delivery in transit',
  },
  {
    key: '我的券包',
    value: 'My ticket packages',
  },
  {
    key: '使用记录',
    value: 'Usage record',
  },
  {
    key: '请选择',
    value: 'Please select',
  },
  {
    key: '请输入内容',
    value: 'Please enter content',
  },
  {
    key: '优惠券/代金券编码',
    value: 'Coupon code',
  },
  {
    key: '类别',
    value: 'Coupon type',
  },
  {
    key: '额度/可使用次数',
    value: 'Amount/Number of use',
  },
  {
    key: '生效时间段',
    value: 'Validity period',
  },
  {
    key: '使用日期',
    value: 'Use date',
  },
  {
    key: '使用状态',
    value: 'Status of use',
  },
  {
    key: '订单内容',
    value: 'The order content',
  },
  {
    key: '订单号',
    value: 'Order Number',
  },
  {
    key: '主页',
    value: 'Homepage',
  },
  {
    key: '操作',
    value: 'Operations',
  },
  {
    key: '订单管理',
    value: 'Order management',
  },
  {
    key: '单个订单不能超过60番产品，请调整提出番数',
    value:
      'The number of products in a single order cannot exceed 60. Please adjust the quantity accordingly.',
  },
  {
    key: '问题产品',
    value: 'Faulty products',
  },
  {
    key: '中国国内物流状态',
    value: 'China domestic logistics status',
  },
  {
    key: '仓库',
    value: 'Warehouse',
  },
  {
    key: '发货委托',
    value: 'Consignment',
  },
  {
    key: '国际物流状态',
    value: 'International logistics status',
  },
  {
    key: '售后',
    value: 'Customer service',
  },
  {
    key: '账户余额管理',
    value: 'Account Balance',
  },
  {
    key: '请先登录账号',
    value: 'Please sign in to your account first',
  },
  {
    key: '支付账单',
    value: 'Pay the bills',
  },
  {
    key: '暂时没有可使用的优惠券',
    value: 'No vouchers available temporarily',
  },
  {
    key: '收藏',
    value: 'collection',
  },
  {
    key: '产品',
    value: 'Products',
  },
  {
    key: '商品库列表',
    value: 'List of commodity inventory',
  },
  {
    key: '个人信息管理',
    value: 'Personal Information management',
  },
  {
    key: '修改个人信息',
    // value: 'Modifying personal information',
    value: 'Personal information',

  },
  {
    key: '我的收货地址',
    value: 'My shipping address',
  },
  {
    key: '员工账户',
    value: 'Employee account',
  },
  {
    key: '更改密码',
    value: 'Change password',
  },
  {
    key: '预购订单',
    value: 'Pre-order',
  },
  {
    key: '全部订单',
    value: 'All',
  },
  {
    key: '等待报价',
    value: 'Awaiting Quote',
  },
  {
    key: '等待付款',
    value: 'Awaiting Payment',
  },
  {
    key: '正在购买',
    value: 'Purchasing',
  },
  {
    key: '购买完成',
    value: 'Purchase Complete',
  },
  {
    key: '已取消',
    value: 'Cancelled',
  },
  {
    key: '已取消(订单状态)',
    value: 'Cancelled (order status)',
  },
  {
    key: '请输入关键词搜索',
    value: 'Please enter a keyword to search',
  },
  {
    key: '开始日期',
    value: 'Start date',
  },
  {
    key: '至',
    value: 'to',
  },
  {
    key: '结束日期',
    value: 'End date',
  },
  {
    key: '搜索',
    value: 'Search',
  },
  {
    key: '导出excel',
    value: 'Export to excel',
  },
  {
    key: '检品中',
    value: 'In inspection',
  },
  {
    key: '检品中订单',
    value: 'Order in inspection',
  },
  {
    key: '上架完了订单',
    value: 'Finished placing the order',
  },
  {
    key: '在RAKUMART仓库中',
    value: "It's in the RAKUMART warehouse",
  },
  {
    key: '订单状态',
    value: 'Order status',
  },
  {
    key: '前往xx页',
    value: 'Go to page xx',
  },
  {
    key: '提出日期',
    value: 'Proposed time',
  },
  {
    key: '付款日期',
    value: 'Payment date',
  },
  {
    key: '购买完成日期',
    value: 'Purchase completion date',
  },
  {
    key: '等待处理',
    value: 'To be processed',
  },
  {
    key: '处理中',
    value: 'Processing',
  },
  {
    key: '所有问题产品详情',
    value: 'Historical issues',
  },
  {
    key: '联系负责人',
    value: 'Contact the person in charge',
  },
  {
    key: '确认签收',
    value: 'Confirm receipt',
  },
  {
    key: '问题提出日期',
    value: 'Date the question was asked',
  },
  {
    key: '有问题的商品数',
    value: 'Quantity of questions',
  },
  {
    key: '问题商品数量',
    value: 'Faulty products quantity',
  },
  {
    key: '暂无数据',
    value: 'No data',
  },
  {
    key: '数据获取错误',
    value: 'Data Retrieval Error!',
  },
  {
    key: '立即支付',
    value: 'Immediate payment',
  },
  {
    key: '问题照片',
    value: 'Problem photos',
  },
  {
    key: '问题说明',
    value: 'Question description',
  },
  {
    key: '处理意见',
    value: 'Reply',
  },
  {
    key: '查看照片',
    value: 'See the photos',
  },
  {
    key: '统一回复',
    value: 'Unified reply',
  },
  {
    key: '单价（元）',
    value: 'Unit price (yuan)',
  },
  {
    key: '请输入处理意见',
    value: 'Please enter the reply',
  },
  {
    key: '全部',
    value: 'All',
  },
  {
    key: '待发货',
    value: 'Awaiting Packinglist',
  },
  {
    key: '等待发货',
    value: 'Pending delivery',
  },
  {
    key: '照片',
    value: 'Pictures',
  },
  {
    key: '属性',
    value: 'Attributes',
  },
  {
    key: '未选择服务',
    value: 'No service selected',
  },
  {
    key: '选择该商品',
    value: 'Select this product',
  },
  {
    key: '单价(出入金)',
    value: 'Unit price (cash in and out)',
  },
  {
    key: '中国运费',
    value: 'China freight',
  },
  {
    key: '国内运费',
    value: 'Domestic freight',
  },
  {
    key: '备注栏',
    value: 'Remarks column',
  },
  {
    key: '状态',
    value: 'Status',
  },
  {
    key: '国内派送中',
    value: 'Domestic delivery in transit',
  },
  {
    key: '没有备注',
    value: 'No comments',
  },
  {
    key: '此订单无备注',
    value: 'No comments for this order',
  },
  {
    key: '回到首页',
    value: 'Back to the homepage',
  },
  {
    key: '可提出',
    value: 'To be collected',
  },
  {
    key: '装箱中',
    value: 'Packing',
  },
  {
    key: '提出配送单',
    value: 'Raise a delivery note',
  },
  {
    key: '产品费用',
    value: 'Product value',
    },
    {
      key: '商品费用小计',
      value: 'Product value',
    },
  {
    key: '购买数量',
    value: 'Purchase quantity',
    },
   
  
  {
    key: '可发货数量',
    value: 'Shippable quantity',
  },
  {
    key: '全部配送单',
    value: 'All delivery order',
  },
  {
    key: '等待付款(订单列表)',
    value: 'Waiting for payment (order list)',
  },
  {
    key: '重量（Kg）',
    value: 'Weight (Kg)',
  },
  {
    key: '国际物流费用',
    value: 'International logistics costs',
  },
  {
    key: '西班牙国内配送中',
    value: 'Domestic distribution in Spain',
  },
  {
    key: '国际运输中',
    value: 'In Transit',
  },
  {
    key: '已清关',
    value: 'Cleared',
  },
  {
    key: '已签收',
    value: 'Delivered',
  },
  {
    key: '美国派送中',
    value: 'US domestic shipping',
  },
  {
    key: '配送单号',
    value: 'Packinglist Number',
  },
  {
    key: '发货时间',
    value: 'Delivery time',
  },
  {
    key: '通关方式',
    value: 'Customs clearance',
  },
  {
    key: '国际运输方式',
    value: 'International Shipping Method',
  },
  {
    key: '箱数',
    value: 'Number of containers',
  },
  {
    key: '去提现',
    value: 'To withdraw',
  },
  {
    key: '剩余可用点数',
    value: 'Remaining available points',
  },
  {
    key: '已充值待确认点数',
    value: 'Points have been recharged to be confirmed',
  },
  {
    key: '提现中',
    value: 'Withdrawing',
  },
  {
    key: '充值',
    value: 'Deposit',
  },
  {
    key: '公司名称',
    value: 'Company Name',
  },
  {
    key: '公司地址',
    value: 'Company address',
  },
  {
    key: '公司营业执照',
    value: 'Business license',
  },
  {
    key: '银行账户',
    value: 'Bank account',
  },
  {
    key: 'SWIFT账户',
    value: 'SWIFT account',
  },
  {
    key: '汇款日期',
    value: 'Remittance date',
  },
  {
    key: '汇款日期不能为空',
    value: 'Remittance date cannot be empty',
  },
  {
    key: '选择日期',
    value: 'Select a date',
  },
  {
    key: '汇款人',
    value: 'Remitter',
  },
  {
    key: '汇款人不能为空',
    value: 'The remitter cannot be empty',
  },
  {
    key: '汇款金额',
    value: 'The remittance amount',
  },
  {
    key: '汇款截图',
    value: 'Screenshot of remittance',
  },
  {
    key: '附加服务数量不能为0',
    value: 'The number of additional services cannot be zero',
  },
  {
    key: '支付',
    value: 'Confirmation payment',
  },
  {
    key: '提现申请信息',
    value: 'Withdrawal application information',
  },
  {
    key: '请输入订单号或配送单号搜索',
    value: 'Please enter the order number ',
  },
  {
    key: '当前账户余额',
    value: 'Current account balance',
  },
  {
    key: '基本信息',
    value: 'Basic Information',
  },
  {
    key: '选择汇款方式',
    value: 'Choosing a payment method',
  },
  {
    key: '请输入用户名',
    value: 'Please enter the user name',
  },
  {
    key: '账户名称',
    value: 'Account name',
  },
  {
    key: '请输入账户名称',
    value: 'Please enter the account name',
  },
  {
    key: '提现金额',
    value: 'Withdrawal amount',
  },
  {
    key: '提现申请原因',
    value: 'Reasons for withdrawal application',
  },
  {
    key: '确认提交',
    value: 'Confirm to submit',
  },
  {
    key: '信息确认',
    value: 'Confirmation of information',
  },
  {
    key: '请输入银行账户',
    value: 'Please enter your bank account',
  },
  {
    key: '请输入提现金额',
    value: 'Please enter the withdrawal amount',
  },
  {
    key: '请输入提现申请原因',
    value: 'Please enter the reason for the withdrawal request',
  },
  {
    key: '请您核对以下信息是否正确',
    value: 'Please confirm whether the following field information is correct',
  },
  {
    key: '汇款取款历史',
    value: 'Remittance and withdrawal history',
  },
  {
    key: '日期（财务确认日期）',
    value: 'Date (Finance confirmation date)',
  },
  {
    key: '交易类型',
    value: 'Transaction Type',
  },
  {
    key: '支付方式',
    value: 'Payment method',
  },
  {
    key: '发票号',
    value: 'Invoice no.',
  },
  {
    key: '总金额',
    value: 'The total amount',
  },
  {
    key: '明细',
    value: 'Details',
  },
  {
    key: '收支',
    value: 'Balance of payments',
  },
  {
    key: '账户余额',
    value: 'Account balance',
  },
  {
    key: '配送单',
    value: 'Packinglist',
  },
  {
    key: '提现记录',
    value: 'Withdrawals record',
  },
  {
    key: '此配送单已正式提出，请勿重复操作',
    value:
      'This delivery order has been formally proposed, please do not repeat operation',
  },
  {
    key: '发起时间',
    value: 'Starting time',
  },
  {
    key: '流水号',
    value: 'Serial number',
  },
  {
    key: '收款户名',
    value: 'Payee account name',
  },
  {
    key: '收款户号',
    value: 'Payee account number',
  },
  {
    key: '处理状态',
    value: 'Processing status',
  },
  {
    key: '待审核',
    value: 'Pending review',
  },
  {
    key: '查看凭证',
    value: 'View document',
  },
  {
    key: '人工调整',
    value: 'Manual adjustment',
  },
  {
    key: '下载',
    value: 'Download',
  },
  {
    key: '已被停用',
    value: 'Has been discontinued',
  },
  {
    key: '是否删除选中的商品',
    value: 'Whether to delete the selected product？',
  },
  {
    key: '是否删除选中的配送单？',
    value: 'Whether to delete the selected shipping order',
  },
  {
    key: '请至少选择一项',
    value: 'Please select at least one item',
  },
  {
    key: '商品链接',
    value: 'Product link',
  },
  {
    key: '商品状态',
    value: 'Product status',
  },
  {
    key: '入库中',
    value: 'In storage',
  },
  {
    key: '商品金额变动明细',
    value: 'Amount change details',
  },
  {
    key: '序号',
    value: 'The serial number',
  },
  {
    key: '调整前数据',
    value: 'Data before adjustment',
  },
  {
    key: '调整后数据',
    value: 'Data after adjustment',
  },
  {
    key: '出入金编号',
    value: 'Money in and out number',
  },
  {
    key: '出入金类别',
    value: 'Type of money in and out',
  },
  {
    key: '调整时间',
    value: 'Adjustment time',
  },
  {
    key: '调整具体明细',
    value: 'Adjustment details',
  },
  {
    key: '入金额',
    value: 'Amount of income',
  },
  {
    key: '出金额',
    value: 'Amount of money',
  },
  {
    key: '正常',
    value: 'Normal',
  },
  {
    key: '请输入备注',
    value: 'Please enter remarks',
  },
  {
    key: '店铺名称',
    value: 'Shop name',
  },
  {
    key: '选中移出',
    value: 'Remove the selected item',
  },
  {
    key: '是否删除此店铺',
    value: 'Whether to delete this store',
  },
  {
    key: '提示',
    value: 'Prompt message',
  },
  {
    key: '缺少必填选项',
    value: 'Required parameter is missing',
  },
  {
    key: '请至少选择一个商品',
    value: 'Please select at least one product',
  },
  {
    key: '商品库提出订单',
    value: 'Place an order from the commodity warehouse',
  },
  {
    key: '商品编号',
    value: 'Product id',
  },
  {
    key: '商品备注详情',
    value: 'Product remark details',
  },
  {
    key: '请输入备注信息',
    value: 'Please enter remarks',
  },
  {
    key: '添加',
    value: 'Add',
  },
  {
    key: '商品库添加商品',
    value: 'Add product to product lists',
  },
  {
    key: '商品图片',
    value: 'Product pictures',
  },
  {
    key: '商品属性',
    value: 'Product attributes',
  },
  {
    key: '添加一行',
    value: 'Add a line',
  },
  {
    key: '请输入商品链接',
    value: 'Please enter product link',
  },
  {
    key: '保存中',
    value: 'Saving',
  },
  {
    key: '修改地址',
    value: 'Modify the address',
  },
  {
    key: '默认地址',
    value: 'Default address',
  },
  {
    key: '同步到进口商',
    value: 'Synchronise to the importer',
  },
  {
    key: '编辑',
    value: 'Edit',
  },
  {
    key: '这是你的控制面板',
    value: 'This is your control panel',
  },
  {
    key: '你好',
    value: 'hello',
  },
  {
    key: '新消息',
    value: 'The new message',
  },
  {
    key: '临时保存的订单',
    value: 'Temporarily saved',
  },
  {
    key: '待处理订单',
    value: 'Awaiting payment orders',
  },
  {
    key: '等待工厂发货的订单',
    value: 'Orders waiting for shipment from the supplier',
  },
  {
    key: '货物到达RAKUMART中国仓库',
    value: "Goods arrive at RAKUMART's warehouse in China",
  },
  {
    key: '质量控制和处理',
    // value: 'Inspection',
    value:'Awaiting inspection'
  },
  {
    key: '国际运费报价',
    value: 'Awaiting packinglist quote',
  },
  {
    key: '国际运费待支付',
    value: 'Awaiting packinglist payment',
  },
  {
    key: '已入仓待装箱发货',
    value: 'Awaiting shipment',
  },
  {
    key: '中国发往美国的订单',
    value: 'Orders from China to the United States',
  },
  {
    key: '货物到达RAKUMART美国仓库',
    value: 'Goods arrive at RAKUMART US warehouse',
  },
  {
    key: '西班牙派送',
    value: 'The United States to send',
  },
  {
    key: '入库完了订单',
    value: 'In stock',
  },
  {
    key: '需要付款的订单',
    value: 'Orders that require payment',
  },
  {
    key: '待支付国际运费',
    value: 'Packlists that reqiure payment',
  },
  {
    key: '待报价',
    value: 'Awaiting Quote',
  },
  {
    key: '待支付',
    value: 'Awaiting Payment',
    },
    {
      key: '等待支付',
      value: 'Awaiting Payment',
    },
  {
    key: '已收货',
    value: 'Delivered',
  },
  {
    key: '国际运费待报价',
    value: 'International freight to be quoted',
  },
  {
    key: '到达RAKUMART物流中心',
    value: 'Arrive at RAKUMART Logistics Center',
  },
  {
    key: '订单待处理',
    value: 'All orders',
  },
  {
    key: '订单到达RAKUMART仓库',
    value: 'Order to RAKUMART warehouse',
  },
  {
    key: '订单运输中',
    value: 'Packinglist',
  },
  {
    key: '已完成订单',
    value: 'Completed orders',
  },
  {
    key: '发票',
    value: 'invoice',
  },
  {
    key: '历史发票',
    value: 'History of invoice',
  },
  {
    key: '发票信息',
    value: 'The invoice information',
  },
  {
    key: '全名',
    value: 'The full name',
  },
  {
    key: '店铺收藏',
    value: 'Favorite shops',
  },
  {
    key: '电子邮箱',
    value: 'Email address',
  },
  {
    key: '发货地址',
    value: 'The delivery address',
  },
  {
    key: '密码修改与验证',
    // value: 'Password change and verification',
    value: 'Password change',

  },
  {
    key: '账户信息摘要',
    value: 'Account Information Summary',
  },
  {
    key: '已发出的订单',
    value: 'Orders that have been placed',
  },
  {
    key: '佣金计算方法',
    value: 'How is the commission calculated?',
  },
  {
    key: '优惠券',
    value: 'Coupon',
  },
  {
    key: '重点订单信息',
    value: 'Key Order Information',
  },
  {
    key: '已到达RAKUMART仓库的订单',
    value: 'The order has arrived at RAKUMART warehouse',
  },
  {
    key: '管理订单',
    value: 'Manage Orders',
  },
  {
    key: '订单状态概况',
    value: 'The order process',
  },
  {
    key: '待支付国际运费的订单',
    value: 'Orders to be paid for international freight',
  },
  {
    key: '美国国内配送中',
    value: 'Delivery in the US',
    },
    {
      key: '报价中',
      value: 'Awaiting Quote',
    },

  {
    key: '已取消的订单',
    value: 'A cancelled order',
  },
  {
    key: '所有订单',
    value: 'All',
  },
  {
    key: '待收货',
    value: 'Awaiting receipt',
  },
  {
    key: '待处理的订单',
    value: 'Pending orders',
  },
  {
    key: '收件人',
    value: 'Recipient',
  },
  {
    key: '临时保存的',
    value: 'Save temporarily',
  },
  {
    key: '出货完成',
    // value: 'To complete delivery',
    value:'In Transit'
   
  },
  {
    key: '进口商',
    value: 'Importer',
  },
  {
    key: '邮编',
    value: 'Post code',
  },
  {
    key: '地址',
    value: 'Address',
  },
  {
    key: '编辑地址',
    value: 'Edit address',
  },
  {
    key: '设为默认地址',
    value: 'Set as default',
  },
  {
    key: '是否删除当前地址',
    value: 'Whether to delete the current address',
  },
  {
    key: '删除取消',
    value: 'Delete cancel',
  },
  {
    key: '地址管理',
    value: 'Address management',
  },
  {
    key: '操作成功',
    value: 'Successful',
  },
  {
    key: '成功删除地址',
    value: 'Successful',
  },
  {
    key: '移除成功',
    value: 'Deleted Successfully',
  },
  {
    key: '您已成功移除选中的商品',
    value: 'Deleted Successfully',
  },
  {
    key: '重置',
    value: 'Reset',
  },
  {
    key: '添加',
    value: 'Add new',
  },
  {
    key: '移除',
    value: 'remove',
  },
  {
    key: '选择标签',
    value: 'Select the tag',
  },
  {
    key: '请输入关键词',
    value: 'Please enter keywords to add',
  },
  {
    key: '格式不正确',
    value: 'Incorrect format',
  },
  {
    key: '同步到收件人',
    value: 'Synchronize to Recipients',
  },
  {
    key: '密码验证',
    value: 'Password validation',
  },
  {
    key: '确认关闭？',
    value: 'Confirm shutdown？',
  },
  {
    key: '请选择验证方式',
    value: 'Please select the authentication method',
  },
  {
    key: '身份验证',
    value: 'Identity confirmation',
  },
  {
    key: '请输入登录密码',
    value: 'Please enter your password',
  },
  {
    key: '请输入旧登录密码',
    value: 'Please enter the old login password',
  },
  {
    key: '下一步',
    value: 'Next step',
  },
  {
    key: '密码错误',
    value: 'Incorrect password',
  },
  {
    key: '登录密码修改',
    value: 'Login password modification',
  },
  {
    key: '新密码',
    value: 'New password',
  },
  {
    key: '购买数量不能超过在库数量',
    value: 'The quantity purchased cannot exceed the quantity in stock',
  },
  {
    key: '再次确认密码',
    value: 'Reconfirm new password',
  },
  {
    key: '请再次输入密码',
    value: 'Please enter the password again',
  },
  {
    key: '返回主页',
    value: 'Return to home page',
  },
  {
    key: '更改成功',
    value: 'Change succeeded',
  },
  {
    key: '登录密码更改成功',
    value: 'Login password changed successfully',
  },
  {
    key: '密码修改成功，请重新登录',
    value: 'Password changed successfully, please log in again',
  },
  {
    key: '当前未登录',
    value: 'Currently not logged in',
  },
  {
    key: '其他认证方式',
    value: 'Other authentication methods',
  },
  {
    key: '请在60秒后重新发送验证码',
    value: 'Please resend the verification code in 60 seconds',
  },
  {
    key: '订单里请至少添加一款商品',
    value: 'Please add at least one item to the order.',
  },
  {
    key: '时间',
    value: 'Time',
  },
  {
    key: '全选',
    value: 'Select all',
  },
  {
    key: '产品信息',
    value: 'Product information',
  },
  {
    key: '备注',
    value: 'Remark',
  },
  {
    key: '店铺名/店铺',
    value: 'Store name/Store',
  },
  {
    key: '店铺',
    value: 'Store',
  },
  {
    key: '选择附加服务',
    value: 'Select additional services',
  },
  {
    key: '追加商品',
    value: 'Add products',
  },
  {
    key: '删除',
    value: 'Delete',
  },
  {
    key: '客户提现出金',
    value: 'The customer withdrew money',
  },
  {
    key: '客户提出现金',
    value: 'Customer withdraws cash',
  },
  {
    key: '产品费用',
    value: 'Product value',
  },
  {
    key: '其他费用',
    value: 'Other fees',
  },
  {
    key: '备注后会同步显示给业务员',
    value: 'Comments will be displayed to the seller at the same time.',
  },
  {
    key: '附加服务选项',
    value: 'Additional service options',
  },
  {
    key: '预计费用',
    value: 'Estimated cost',
  },
  {
    key: '合计约为',
    value: 'Estimated total',
  },
  {
    key: '出入金记录',
    value: 'Deposit/withdrawal record',
  },
  {
    key: '确定',
    value: 'Confirm',
  },
  {
    key: '收件工资',
    value: 'Receive a wage',
  },
  {
    key:
      '的发送：您已正确付款并且您的货物已在发送至我们的仓库。 商品到货时将提供国家快递（GLS）的追踪号码。您可以在个人中心中查看状态。',
    value:
      ':you have paid correctly and your goods have been sent to our warehouse. The tracking number of National Express (GLS) will be provided when the goods arrive. You can view the status in Personal Center.',
  },
  {
    key: '有必填项未填写',
    value: 'Required fields are missing',
  },
  {
    key: '唛头',
    value: 'Mark',
  },
  {
    key: '请输入完整电子邮箱',
    value: 'Please enter the complete email address',
  },
  {
    key: '联系我们',
    value: 'Contact us',
  },
  {
    key: '汇款金额需要大于当前不足的金额',
    value:
      'The remittance amount should be  greater than the current insufficient amount',
  },
  {
    key: '支付金额不能少于最低入金金额 ',
    value:
      'The payment amount cannot be less than the minimum deposit amount   ',
  },
  {
    key: '余额不足',
    value: 'Insufficient balance',
  },
  {
    key: '提醒',
    value: 'Remind',
  },
  {
    key: '当前余额不足，是否前往充值',
    value: 'Current balance is insufficient, whether to go to recharge',
  },
  {
    key: '删除订单',
    value: 'Deleting an order',
  },
  {
    key:
      '当前订单商品暂无库存，无法完成购买。您可以调整订单重新提出 也可以直接删除该订单。',
    value:
      'The goods of the current order are not in stock, so the purchase cannot be completed. You can rearrange the order or delete the order directly.',
  },
  {
    key: '附加服务选择',
    value: 'Additional service options',
  },
  {
    key: '该订单已正式提出，勿重复提出',
    value: 'This order has already been placed, please do not place again.',
  },
  {
    key: '购物车为空',
    value: 'Shopping cart is empty',
  },
  {
    key: '收藏夹',
    value: 'Favorites',
  },
  {
    key: '调查在库数量',
    value: 'Investigate the quantity in stock',
    },
    {
      key: '在库数量',
      value: 'Quantity in stock',
  },
  {
    key: '预计到货成本',
    value: 'Estimated arrival cost',
  },
  {
    key: '特殊备注要求',
    value: 'Remarks of order',
  },
  {
    key: '全部费用明细',
    value: 'Full cost details',
  },
  {
    key: '修改订单',
    value: 'Change Order',
  },
  {
    key: 'RAKUMART订单价值',
    value: 'RAKUMART order value',
  },
  {
    key: '对该订单有特殊要求的，可以在这边备注',
    value:
      'If you have special requirements for this order, you can remark here',
  },
  {
    key: '重新提出',
    value: 'Resubmit',
  },
  {
    key: '调查单价',
    value: 'Investigate unit price',
  },
  {
    key: '关闭',
    value: 'Close',
  },
  {
    key: '选择优惠券或代金券',
    value: 'Choose a coupon',
  },
  {
    key:
      '温馨提示：一个订单只能使用一张优惠券，优惠券适用于订单、代金券适用于配送单',
    value:
      'Tips: One order can only use one coupon, free coupon for order, voucher for delivery order',
  },
  {
    key: '代金券',
    value: 'Voucher',
  },
  {
    key: '余额支付',
    value: 'Pay with points',
  },
  {
    key: '支付成功',
    value: 'payment successful',
  },
  {
    key: '汇款金额不能为空',
    value: 'Remittance amount cannot be empty',
  },
  {
    key: '请上传图片',
    value: 'Please upload a picture',
  },
  {
    key: '是否使用银行卡支付',
    value: 'Whether to pay by bank card',
  },
  {
    key: '付款流水号',
    value: 'Payment serial number',
  },
  {
    key: '回到个人中心',
    value: 'Back to user profile',
  },
  {
    key: '汇款金额不能小于最低汇款金额',
    value:
      'The remittance amount cannot be less than the minimum remittance amount',
  },
  {
    key: '发货日期',
    value: 'The date of issuance',
  },
  {
    key: '包含订单',
    value: 'Include orders',
  },
  {
    key: '暂无单号',
    value: 'No order number',
  },
  {
    key: '配送单状态',
    value: 'Distribution order status',
  },
  {
    key: '已确认付款',
    value: 'Payment confirmed',
  },
  {
    key: '美国派送时间',
    value: 'American delivery time',
  },
  {
    key: '中国国内运费',
    value: 'China domestic freight',
  },
  {
    key: '国内',
    value: 'Domestic',
  },
  {
    key: '关税',
    value: 'Tariff',
  },
  {
    key: '预计成本',
    value: 'Estimated cost',
  },
  {
    key: '税金',
    value: 'Tax',
  },
  {
    key: '总成本',
    value: 'Total cost',
  },
  {
    key: '目的地',
    value: 'destination',
  },
  {
    key: '航运标志',
    value: 'The shipping mark',
  },
  {
    key: '表格下载',
    value: 'Download form',
  },
  {
    key: '形式发票',
    value: 'Proforma invoice',
  },
  {
    key: '全选/取消',
    value: 'Select all/Cancel',
  },
  {
    key: '优惠金额',
    value: 'Discounted price',
  },
  {
    key: '支付金额',
    value: 'Payment amount',
  },
  {
    key: '上架中',
    value: 'Partial Goods Stocked',
  },
  {
    key: '金额',
    value: 'Amount',
  },
  {
    key: '请输入支付金额',
    value: 'Please enter the payment amount',
  },
  {
    key: '单番合计',
    value: 'total',
  },
  {
    key: '订单',
    value: 'Orders',
  },
  {
    key: '账户',
    value: 'Account',
  },
  {
    key: '前往登录',
    value: 'Go to login',
  },
  {
    key: '请选择商品',
    value: 'Please select the product',
  },
  {
    key: '问题商品',
    value: 'Faulty products',
  },
  {
    key: '公司介绍',
    value: 'Company Profile',
  },
  {
    key: '会员服务条款',
    value: 'Membership Terms of Service',
  },
  {
    key: '用户指南',
    value: 'User Guide',
  },
  {
    key: ' 用户指南',
    value: 'User guide',
  },
  {
    key: '国际运费',
    value: 'International Shipping Cost',
  },
  {
    key: '一般条款和条件',
    value: 'General terms and conditions',
  },
  {
    key: '法律警告',
    value: 'Legal warning',
  },

  {
    key: '已付款待财务确认',
    value: 'Awaiting financial confirmation for deposit',
  },
  {
    key: '衣服',
    value: 'Clothing',
  },
  {
    key: '商品种类',
    value: 'Types of goods',
  },
  {
    key: '海上运输',
    value: 'Sea transportation',
  },
  {
    key: '操作菜单',
    value: 'Action Menu',
  },
  {
    key: '全部的费用',
    value: 'Full cost',
  },
  {
    key: '暂无优惠券',
    value: 'No coupons yet',
  },
  {
    key: '无佣金',
    value: 'No commission',
  },
  {
    key: '中国国内所有费用',
    value: 'Total cost in China',
  },
  {
    key: '商品价值',
    value: 'Commodity value',
  },
  {
    key: '美国物流预估',
    value: 'Us Logistics estimates',
  },
  {
    key: '反倾销税预估',
    value: 'Estimated Anti-dumping duty',
  },
  {
    key: '美国预估',
    value: 'Estimated U.S. ',
  },
  {
    key: '问题图片',
    value: 'Problem photos',
  },
  {
    key: '问题产品细节',
    value: 'Faulty products details',
  },
  {
    key: '添加选中商品',
    value: 'Add selected item',
  },
  {
    key: '可提货数量',
    value: 'Quantity available for delivery',
  },
  {
    key: '提示：配送单信息如有修改，请联系您的负责人员',
    value:
      'Reminder: If the delivery note information is modified, please contact your responsible person',
  },
  {
    key: '收货地址',
    value: 'Shipping address',
  },
  {
    key: '请输入邮编',
    value: 'Please enter the post code',
  },
  {
    key: '请输入联系人',
    value: 'Please enter a contact person',
  },
  {
    key: '请输入手机',
    value: 'Please enter a mobile phone number',
  },
  {
    key: '请输入电子邮箱',
    value: 'Please enter email address',
  },
  {
    key: '请输入CNPJ',
    value: 'Please enter CNPJ',
  },
  {
    key: '收货地址详情',
    value: 'Shipping address details',
  },
  {
    key: 'RAKUMART代通关',
    value: 'RAKUMART agent customs clearance',
  },
  {
    key: '美国物流费用',
    value: 'US logistics costs',
  },
  {
    key: '增值税',
    value: 'VAT',
  },

  {
    key: '反倾销税',
    value: 'Anti-dumping duties',
  },
  {
    key: '提示文字',
    value: 'Prompt text',
  },
  {
    key: '提交',
    value: 'Submit',
  },
  {
    key: '汇款图片不能为空',
    value: 'The remittance picture cannot be empty',
  },
  {
    key: '修改唛头',
    value: 'Change the mark',
  },
  {
    key: '修改SSN',
    value: 'Change SSN',
  },
  {
    key: '阿拉巴马州',
    value: 'Alabama',
  },
  {
    key: '阿拉斯加州',
    value: 'Alaska',
  },
  {
    key: '亚利桑那州',
    value: 'Arizona',
  },
  {
    key: '阿肯色州',
    value: 'Arkansas',
  },
  {
    key: '加利福尼亚州',
    value: 'California',
  },
  {
    key: '科罗拉多州',
    value: 'Colorado',
  },
  {
    key: '康涅狄格州',
    value: 'Connecticut',
  },
  {
    key: '德拉华州',
    value: 'Delaware',
  },
  {
    key: '(华盛顿)哥伦比亚特区',
    value: 'District of Columbia',
  },
  {
    key: '佛罗里达州',
    value: 'Florida',
  },
  {
    key: '乔治亚州',
    value: 'Georgia',
  },
  {
    key: '佐治亚州',
    value: 'Georgia',
  },
  {
    key: '夏威夷州',
    value: 'Hawaii',
  },
  {
    key: '爱达荷州',
    value: 'Idaho',
  },
  {
    key: '科罗拉多州',
    value: 'Colorado',
  },
  {
    key: '康涅狄格州',
    value: 'Connecticut',
  },
  {
    key: '德拉华州',
    value: 'Delaware',
  },
  {
    key: '(华盛顿)哥伦比亚特区',
    value: 'District of Columbia',
  },
  {
    key: '佛罗里达州',
    value: 'Florida',
  },
  {
    key: '乔治亚州',
    value: 'Georgia',
  },
  {
    key: '夏威夷州',
    value: 'Hawaii',
  },
  {
    key: '爱达荷州',
    value: 'Idaho',
  },
  {
    key: '特拉华州',
    value: 'Delaware',
  },
  {
    key: '伊利诺州',
    value: 'Illinois',
  },
  {
    key: '印地安那州',
    value: 'Indiana',
  },
  {
    key: '衣阿华州',
    value: 'Iowa',
  },
  {
    key: '堪萨斯州',
    value: 'Kansas',
  },
  {
    key: '肯塔基州',
    value: 'Kentucky',
  },
  {
    key: '路易斯安那州',
    value: 'Louisiana',
  },
  {
    key: '缅因州',
    value: 'Maine',
  },
  {
    key: '马里兰州',
    value: 'Maryland',
  },
  {
    key: '麻萨诸塞州',
    value: 'Massachusetts',
  },
  {
    key: '密执安州',
    value: 'Michigan',
  },
  {
    key: '明尼苏达州',
    value: 'Minnesota',
  },
  {
    key: '密西西比州',
    value: 'Mississippi',
  },
  {
    key: '密苏里州',
    value: 'Missouri',
  },
  {
    key: '蒙大拿州',
    value: 'Montana',
  },
  {
    key: '内布拉斯加州',
    value: 'Nebraska',
  },
  {
    key: '内华达州',
    value: 'Nevada',
  },
  {
    key: '新罕布什尔州',
    value: 'New Hampshire',
  },
  {
    key: '新泽西州',
    value: 'New Jersey',
  },
  {
    key: '新墨西哥州',
    value: 'New Mexico',
  },
  {
    key: '纽约州',
    value: 'New York',
  },
  {
    key: '北卡罗来纳州',
    value: 'North Carolina',
  },
  {
    key: '北达科他州',
    value: 'North Dakota',
  },
  {
    key: '俄亥俄州',
    value: 'Ohio',
  },
  {
    key: '俄克拉荷马州',
    value: 'Oklahoma',
  },
  {
    key: '宾夕法尼亚州',
    value: 'Pennsylvania',
  },
  {
    key: '俄勒冈州',
    value: 'Oregon',
  },
  {
    key: '罗得岛州',
    value: 'Rhode Island',
  },
  {
    key: '南达科他州',
    value: 'South Dakota',
  },
  {
    key: '内布拉斯加州',
    value: 'South Carolina',
  },
  {
    key: '南卡罗来纳州',
    value: 'South Carolina',
  },
  {
    key: '田纳西州',
    value: 'Tennessee',
  },
  {
    key: '德克萨斯州',
    value: 'Texas',
  },
  {
    key: '犹他州',
    value: 'Utah',
  },
  {
    key: '佛蒙特州',
    value: 'Vermont',
  },
  {
    key: '弗吉尼亚州',
    value: 'Virginia',
  },
  {
    key: '华盛顿州',
    value: 'Washington',
  },
  {
    key: '西弗吉尼亚州',
    value: 'West Virginia',
  },
  {
    key: '威斯康星州',
    value: 'Wisconsin',
  },
  {
    key: '怀俄明州',
    value: 'Wyoming',
  },
  {
    key: '请输入邮箱',
    value: 'Please input an email address',
  },
  {
    key: '选择',
    value: 'Select',
  },
  {
    key: '请填写验证码',
    value: 'Please fill in the verification code',
  },
  {
    key: '填写信息',
    value: 'Need information',
  },
  {
    key: '美国',
    value: 'US',
    },
    {
      key: "必填参数缺失",
      value:"Required parameter missing",
    },
    {
      key: '图形验证码缺失',
      value: 'The graphical verification code is missing.',
    },
    {
      key: '图形验证码错误',
      value:"The graphical verification code is incorrect.",
    },
    {
      key: '接收号码为空',
      value:"The receiving number is empty.",
    },
  
  {
    key: '请输入验证码',
    value: 'Please enter verification code',
  },
  {
    key: '使用条款和隐私政策还没有被同意。',
    value: 'The Terms of Use and Privacy Policy have not been agreed to.',
  },
  {
    key: '返回重新编辑',
    value: 'Back to edit',
  },
  {
    key: '暂无库存',
    value: 'Out of stock',
  },
  {
    key: '秒后可重新发送验证码',
    value: 'The verification code can be resent in seconds',
  },
  {
    key: '费用($)',
    value: 'Cost ($)',
  },
  {
    key: '箱号',
    value: 'Container number',
  },
  {
    key: '长',
    value: 'Length',
  },
  {
    key: '宽',
    value: 'Width',
  },
  {
    key: '番号',
    value: 'Mash',
  },
  {
    key: '装箱数量',
    value: 'Cartons',
  },
  {
    key: '请选择预设收货地址',
    value: 'Please select the default delivery address',
  },
  {
    key: '欢迎光临Rakumart',
    value: 'Welcome to Rakumart',
  },
  {
    key: '销量',
    value: 'Best selling',
  },
  {
    key: '是否登录',
    value: 'Whether the login',
  },
  {
    key: '是否前往登录?',
    value: 'Are you sure you want to log in',
  },
  {
    key: '是否提交充值信息？',
    value: 'Are you sure you want to submit topup information?',
  },
  {
    key: '请选择预设进口商',
    value: 'Please choose a default importer',
  },
  {
    key: '该商品因库存不足或其他原因已经找不到，请查看其他商品！',
    value:
      'The product has not been found due to insufficient stock or other reasons, please check other products!',
  },
  {
    key: '返回首页',
    value: 'Return to the home page',
  },
  {
    key: '已使用代金券',
    value: 'The voucher has been used',
  },
  {
    key: '美国物流发货时间',
    value: 'US logistics delivery time',
  },
  {
    key: '美国国内运输费用',
    value: 'American logistics',
  },
  {
    key: '已删除',
    value: 'Deleted',
  },
  {
    key: '已删除(订单状态)',
    value: 'Deleted',
  },
  {
    key: '已使用',
    value: 'Used',
  },
  {
    key: '装箱信息',
    value: 'Packing information',
  },
  {
    key: '电子邮箱修改',
    value: 'Email address update',
  },
  {
    key: '手机号修改',
    value: 'Mobile phone number update',
  },
  {
    key: '手机号输入错误',
    value: 'Cellphone number input error',
  },
  {
    key: '提出订单',
    value: 'Place an order',
  },
  {
    key: '全部费用',
    value: 'Full cost',
  },
  {
    key: '附加服务费',
    value: 'Additional services fees',
  },
  {
    key: '选择国际运输公司',
    value: 'Choose an international shipping company',
  },
  {
    key: '选择代金券',
    value: 'Choose a voucher',
  },
  {
    key: '选择优惠券',
    value: 'Choose Coupons',
  },
  {
    key: '请选择您的物流方式，不同的物流方式价格不同',
    value:
      'Please choose your logistics method, different logistics methods have different prices',
  },
  {
    key: '特殊要求备注',
    value: 'Special requirements remarks',
  },
  {
    key: '保存',
    value: 'Save',
  },
  {
    key: '人民币',
    value: 'RMB',
  },
  {
    key: '取消',
    value: 'Cancel',
  },
  {
    key: '联系人',
    value: 'Contact person',
  },
  {
    key: '详情',
    value: 'Product attributes',
  },
  {
    key: '付款',
    value: 'Payment',
  },
  {
    key: '使用优惠券',
    value: 'Use coupons',
  },
  {
    key: '查看全部',
    value: 'View all',
  },
  {
    key: '工作人员回复',
    value: 'Reply to customers',
  },
  {
    key: '单番中国所有费用',
    value: 'Single all costs in China',
  },
  {
    key: '暂时没有可使用的代金券',
    value: 'There are currently no available coupons to use',
  },
  {
    key: '编号',
    value: 'Serial number',
  },
  {
    key: '免手续费、无佣金',
    value: 'No fees, no commissions',
  },
  {
    key: '已使用优惠',
    value: 'Discount used',
  },
  {
    key: '是否使用余额支付?',
    value: 'Whether to use the balance to pay?',
  },
  {
    key: '已取消支付',
    value: 'Payment cancelled',
  },
  {
    key: '确认付款',
    value: 'Confirmation payment',
  },
  {
    key: '请输入订单号搜索',
    value: 'Please enter the order number',
  },
  {
    key: '备注不能为空',
    value: 'Remarks cannot be empty',
  },
  {
    key: '图片上传中',
    value: 'Image uploading',
  },
  {
    key: '汇款金额不能小于订单金额',
    value:
      'The remittance amount should be  greater than the current insufficient amount',
  },
  {
    key: '中国总成本',
    value: 'Total cost in China',
  },
  {
    key: '您即将查看配送单的详细内容，您可以在当前界面查看国际运费的报价信息。',
    value:
      'you are currently waiting for the details page to pay for the delivery order.',
  },
  {
    key:
      '（因为客户直接调整将导致产品的实际包装数据异常，我们限制了客户直接调整交付订单的功能，请谅解。）',
    value:
      ' (Because directly adjusting the customer will cause the actual packaging data of the product to be abnormal, we have limited the function of directly adjusting the customer delivery order, please forgive us.)',
  },
  {
    key:
      '确认无误后，您可以点击”去付款“进行支付。确认收款后，我们将安排物流发货。物流更进详情将通过邮件发给您。如果您需要调整产品包装等，请将详细信息发送给我们。根据您的修改要求进行调整后，我们将提供新的报价。',
    value:
      'You can view the quotation information of specific international rates on the current page. After confirming that there is no problem, you can click the "To the payment" button to make payment. After confirming the receipt, we will arrange the logistics delivery. The logistics status will be sent to you by mail. If you need to adjust the products you send, please send the details to the seller. We will make a new quotation after adjusting according to your modified requirements.',
  },

  {
    key: '汇率',
    value: 'Rate',
  },
  {
    key: '美国物流成本',
    value: 'US logistics costs',
  },
  {
    key: '订单备注',
    value: 'Remarks of order',
  },
  {
    key: '运货方式',
    value: 'Shipping method',
  },
  {
    key: '销售合同',
    value: 'Sales Contract',
  },
  {
    key: '产品成本',
    value: 'Product cost',
  },
  {
    key: '预估国际运费',
    value: 'Estimated international freight',
  },
  {
    key: '预估美国国内运输',
    value: 'Estimated US domestic delivery fee',
  },
  {
    key: '总体积',
    value: 'Total volume',
  },
  {
    key: '总价',
    value: 'Total price',
  },
  {
    key: '实际支付金额',
    value: 'Actual payment amount',
  },
  {
    key: '费用明细',
    value: 'Fee in total Charge details ',
  },
  {
    key: '体积',
    value: 'Volume',
  },
  {
    key: '重量',
    value: 'Weight',
  },
  {
    key: '单番合计',
    value: 'total',
  },
  {
    key: '内容',
    value: 'Content',
  },
  {
    key: '国内物流状态',
    value: 'Domestic logistics status',
  },
  {
    key: '个人资料',
    value: 'Personal information',
  },
  {
    key: '初学者指南',
    value: "Beginner's Guide",
  },
  {
    key: '关于公司',
    value: 'About the company',
  },
  {
    key: '隐私政策',
    value: 'Privacy Policy',
  },
  {
    key: ' 隐私政策',
    value: 'Privacy policy',
  },
  {
    key: '表格',
    value: 'Sheet',
  },
  {
    key: '请输入正确的手机号',
    value: 'Cellphone number input error',
  },
  {
    key: '常见问题解答',
    // value: 'Frequently Asked Questions',
    value:'FAQ'
  },
  {
    key: '是否使用在线支付？',
    value: 'Do you want to use online payment?',
  },
  {
    key: '加载中',
    value: 'Loading',
  },
  {
    key: '共',
    value: 'A total of ',
  },
  {
    key: '个产品',
    value: 'Products',
  },
  {
    key: '数量总计',
    value: 'Total quantity',
  },
  {
    key: '商品库',
    value: 'Product lists',
  },
  {
    key: '美元',
    value: '$',
  },
  {
    key: '国际物流预估',
    value: 'Estimated International Logistics',
  },
  {
    key: '美国物流预估',
    value: 'Estimated U.S. Logistics',
  },
  {
    key: '增值税预估',
    value: 'Estimated VAT',
  },
  {
    key: '预计总成本',
    value: 'Estimated total cost',
  },
  {
    key: '全部费用预估',
    value: 'Estimated total fees',
  },
  {
    key: '负责人回复',
    value: 'The person in charge replies',
  },
  {
    key: '国际船运A',
    value: 'International Shipping A',
  },
  {
    key: '名称',
    value: 'Name',
  },
  {
    key: '计费数据',
    value: 'Billing data',
  },
  {
    key: '提出数',
    value: 'Quantity collected',
  },
  {
    key: '提货数量',
    value: 'Pickup quantity',
  },
  {
    key: '费用总计',
    value: 'Fee in total',
  },
  {
    key: '收件公司',
    value: 'Receiving company',
  },
  {
    key: '请输入收件公司',
    value: 'Please enter the recipient company',
  },
  {
    key: '请输入地址',
    value: 'Please enter an address',
  },
  {
    key: '请输入电话',
    value: 'Please enter a phone number',
  },
  {
    key: '个人通关',
    value: 'Individual clearance',
  },
  {
    key: '待处理',
    value: 'To be processed',
  },
  {
    key: '日期',
    value: 'Date',
  },
  {
    key: '余额',
    value: 'Balance',
  },
  {
    key: '修改姓氏',
    value: 'Change last name',
  },
  {
    key: '确认修改',
    value: 'Confirm the changes',
  },
  {
    key: '修改名字',
    value: 'Change first name',
  },
  {
    key: '邮箱地址',
    value: 'Email address',
  },
  {
    key: '确认关闭?',
    value: 'Confirm to close',
  },
  {
    key: '付款后账户剩余',
    value: 'Balance remaining after payment',
  },
  {
    key: '请上传支付截图',
    value: 'Please upload a screenshot of payment',
  },
  {
    key: '请选择州',
    value: 'Please select State',
  },
  {
    key: '姓氏长度不能大于24位',
    value: 'The last name cannot be longer than 24 characters',
  },
  {
    key: '西班牙',
    value: 'España',
  },
  {
    key: '请输入登录名',
    value: 'Please enter your account name',
  },
  {
    key: '发送方式',
    value: 'Shipping method',
  },
  {
    key: '物流单号',
    value: 'Shipment number',
  },
  {
    key: '费用($)',
    value: 'cost($)',
  },
  {
    key: '实际重量',
    value: 'Actual Weight',
  },
  {
    key: '高',
    value: 'Height',
  },
  {
    key: '当前箱号',
    value: 'Current box number',
  },
  {
    key: '注意',
    value: 'Notice',
  },
  {
    key: '图片',
    value: 'Picture',
  },
  {
    key: '如果您想要修改配送单信息，请联系您的负责人员',
    value:
      'If there is any modification to the delivery order information, please contact your responsible person',
  },
  {
    key: '已付款待确认',
    value: 'Payment to be confirmed',
  },
  {
    key: '前往付款',
    value: 'To the payment',
  },

  {
    key: '操作失败',
    value: 'Operation failed',
  },
  {
    key: '邮箱、手机号或密码输入错误',
    value: 'Email address, mobile phone number or password entered incorrectly',
  },
  {
    key: '邮箱',
    value: 'Email',
  },
  {
    key: '是否确认删除？',
    value: 'Are you sure you want to delete?',
  },
  {
    key: '已使用优惠券',
    value: 'Coupons used',
  },
  {
    key: '清关时间',
    value: 'Customs clearance time',
  },
  {
    key: '未达到使用条件',
    value: 'The conditions of use not met',
  },
  {
    key: '摄影',
    value: 'Photograph',
  },
  {
    key: '摄影服务',
    value: 'Photography services',
  },
  {
    key: '贴条形码',
    value: 'Bar Codes',
  },
  {
    key: '配套',
    value: 'Set Packing',
  },
  {
    key: '取布标',
    value: 'Label Removal',
  },
  {
    key: '缝布标',
    value: 'Label Sewing',
  },
  {
    key: '挂吊牌',
    value: 'Tag Hanging',
  },
  {
    key: '取吊牌',
    value: 'Tag Removal',
  },
  {
    key: '更换OPP袋',
    value: 'OPP Bag Replacement',
  },
  {
    key: '气泡膜包装',
    value: 'Bubble Film Packaging',
  },
  {
    key: '单面印刷',
    value: 'Single-sided Printing',
  },
  {
    key: '双面印刷',
    value: 'Double-sided Printing',
  },
  {
    key: '金额变动明细',
    value: 'Amount Change Details',
  },
  {
    key: '元/个',
    value: 'per image',
  },
  {
    key: '元/张',
    value: 'por zhang',
  },
  {
    key: '点击查看详情',
    value: 'Click for details',
  },
  {
    key: '包括装OPP袋或者换OPP袋',
    value: 'Including packing OPP bag or replacing OPP bag',
  },
  {
    key: '4种商品以上配套手续费另行商谈',
    value: 'Packing fee for more than 4 products to be inquired separately',
  },
  {
    key: '请参照右侧图片',
    value: 'Please refer to the images on the right.',
  },
  {
    key: '默认为A4纸张，其它类型纸张或小册子印刷请另行商谈',
    value:
      'The default is A4 paper, other types of paper or booklet printing please inquire separately.',
  },
  {
    key: '默认到货商品检查项目',
    value: 'Default arrival goods inspection items',
  },
  {
    key: '产品是否按订单发货',
    value: 'Whether the product is shipped according to the order',
  },
  {
    key: '外观有无损坏',
    value: 'Whether there is any damage to the appearance',
  },
  {
    key: '产品页面与产品是否存在较大差异？',
    value:
      'Is there a big difference between the product page and the product？',
  },
  {
    key:
      '我们将针对到货的商品主要针对以上三点进行检查，问题的商品，我们将向客户反馈。',
    value:
      'We will mostly check the above three points for the goods that arrive.We will feedback to the customer if there is any problem.',
  },
  {
    key: '大件商品请另行商谈',
    value: 'For large items, please inquire separately.',
  },
  {
    key: '操作内容',
    value: 'Operating content',
  },
  {
    key:
      '系统将根据您在本网站上累计出货的商品金额来决定手续费等级。不同的已出货的商品金额，对应着不同的会员等级。详情请参考下面的表格明细。',
    value:
      'The system will be based on the total amount of goods shipped on your website to determine the fee level. Different amounts of goods shipped correspond to different membership levels. Please refer to the table details below for details.',
  },
  {
    key:
      '例如：您累计出货的商品金额超过50,000欧元，您之后再下的订单手续费比例将变为9％。',
    value:
      'For example, if you have shipped more than 50,000 EUROS, your subsequent orders will be subject to a 9% commission.',
  },
  {
    key: '采购商品金额',
    value: 'Amount of goods purchased',
  },
  {
    key: '签收时间',
    value: 'Time of Receipt',
  },
  {
    key: '海关',
    value: 'The customs',
  },
  {
    key: '已通关',
    value: 'Have customs clearance',
  },
  {
    key: '商品调整',
    value: 'Commodity adjustment',
  },
  {
    key: '已收货并检品完成',
    value: 'The goods have been received and inspected',
  },
  {
    key: '已打款',
    value: 'Have money',
  },
  {
    key: '邮箱更改成功',
    value: 'Email changed successfully',
  },
  {
    key: '是否同步到进口商',
    value: 'Whether to synchronize to the importer',
  },
  {
    key: '是否同步到收件人',
    value: 'Whether to synchronize to the recipient',
  },
  {
    key: '您可以在这边添加商品备注',
    value: 'You can add product remarks here',
  },
  {
    key: '该订单状态不可支付',
    value: 'The order has been paid',
  },
  {
    key: '该配送单状态不可支付',
    value: 'The status of the delivery order is not payable',
  },
  {
    key: '为编辑',
    value: '1',
  },
  {
    key: '为编辑',
    value: '1',
  },
  {
    key: '为编辑',
    value: '1',
  },
  {
    key: '为编辑',
    value: '1',
  },
  {
    key: '为编辑',
    value: '1',
  },
  {
    key: '自动签收时间',
    value: 'Automatic receipt time',
  },
  {
    key: '同意',
    value: 'Consent',
  },
  {
    key: '可用',
    value: 'Available',
  },
  {
    key: '过期',
    value: 'past due',
  },
  {
    key: '已过期',
    value: 'Expired',
  },
  {
    key: '有效期',
    value: 'Validity period',
  },
  {
    key: '今日到期',
    value: 'Due today',
  },
  {
    key: '订单金额',
    value: 'Order amount',
  },
  {
    key: '店铺加载中',
    value: 'Store loading',
  },
  {
    key: '请输入6-16位包含大小写字母和数字的密码',
    value:
      'Please enter a 6-16 character string containing uppercase and lowercase letters and numbers',
  },
  {
    key: '提出配送单请将地址填写完整',
    value:
      'Please fill in the address completely when submitting the shipping order',
  },
  {
    key: '提出数不能超过可提出数',
    value: 'The proposable number cannot exceed the proposable number',
  },
  {
    key: '此店铺暂无商品',
    value: 'There are no products in this store',
  },
  {
    key: '选择通关方式',
    value: 'Choose a customs clearance method',
  },
  {
    key: '立即生成',
    value: 'Generate now',
  },
  {
    key: '点击选择附加服务',
    value: 'Click to select additional services',
  },
  {
    key: '未查找到该账号，请核对后再尝试或联系客服处理',
    value:
      'The account number is not found, please check and try again or contact customer service for processing',
  },
  {
    key: '发票上传',
    value: 'Invoice upload',
  },
  {
    key: '已发货',
    value: 'In transit',
  },
  {
    key: '双清包税',
    value: 'Double clearance tax',
  },
  {
    key: '自行交税通关',
    value: 'Pay your own taxes',
  },
  {
    key: '描述',
    value: 'describe',
  },
  {
    key: '同意规约，完成注册',
    value: 'Agree to the statute and complete the registration',
  },
  {
    key: 'RAKUMART商品采购服务的描述',
    value: "Description of RAKUMART's commodity procurement service",
  },
  {
    key: '通用操作程序的描述',
    value: 'Description of common operating procedures',
  },
  {
    key: '客户个人中心的功能描述',
    value: 'Description of the function of the customer Personal Center',
  },
  {
    key: '提现金额不可大于当前账户余额',
    value:
      'The withdrawal amount cannot be greater than the current account balance',
  },
  {
    key: '客户提现',
    value: 'Customer cash withdrawal',
  },
  {
    key: '美国物流单号',
    value: 'US tracking number',
  },
  {
    key: '美国物流方式',
    value: 'American logistics methods',
  },

  {
    key: '是否取消当前订单？?',
    value: 'Whether to cancel the selected order？',
  },
  {
    key: '收件地址',
    value: 'Shipping Address',
  },
  {
    key: '签收',
    value: 'Sign for receipt',
  },
  {
    key: '是否签收选中的商品？',
    value: 'Do you sign for the selected item?',
  },
  {
    key: '签收成功',
    value: 'Signed successfully',
  },
  {
    key: '手机号更改成功',
    value: 'Phone number changed successfully',
  },
  {
    key: '加入商品库成功',
    value: 'Successfully added to the product lists',
  },
  {
    key: '最低汇款金额',
    value: 'Minimum remittance amount',
  },
  {
    key: '商品库为空',
    value: 'Product list is empty',
  },
  {
    key: '已取消签收',
    value: 'Sign for receipt cancelled',
  },
  {
    key: '查看更多',
    value: 'To view more',
  },
  {
    key: '注册帐户',
    value: 'Registered account',
  },
  {
    key: '视频介绍',
    value: 'Tutorial video',
  },
  {
    key: '搜索产品',
    value: 'Search products',
  },
  {
    key: '如何下订单',
    value: 'How to place an order',
  },
  {
    key: '如何提交交付请求',
    value: 'How do I submit a delivery request',
  },
  {
    key: '订单付款',
    value: 'The order of payment',
  },
  {
    key: '修改订单和交货订单',
    value: 'Modify orders and delivery orders',
  },
  {
    key: '发票生成失败，请检查您是否有默认地址，如无法解决，请直接联系负责人',
    value:
      'Invoice generation failed, please check whether you have a default address, if not, please directly contact the responsible person',
  },
  {
    key: '资本项目',
    value: 'The capital account',
  },
  {
    key: '最喜欢的',
    value: 'favorite',
  },
  {
    key: '个人数据',
    value: 'Personal data',
  },
  {
    key: '国家运输',
    value: 'The national transportation',
  },
  {
    key: '与费用支付有关的问题',
    value: 'Issues relating to payment of fees ',
  },
  {
    key: '货物运输的清关问题',
    value: 'Customs clearance for shipment',
  },
  {
    key: '关于商店和产品',
    value: 'About stores and products',
  },
  {
    key: '货物的售后加工',
    value: 'After-sale processing of goods',
  },
  {
    key: '规则协议',
    value: 'Rules and Agreement',
  },
  {
    key: '美国国内物流',
    value: 'US domestic logistics',
  },
  {
    key: '国际物流',
    value: 'International Freight',
  },
  {
    key: '全部通知',
    value: 'All notifications',
  },
  {
    key: '已读',
    value: 'Read',
  },
  {
    key: '未读',
    value: 'Unread',
  },
  {
    key: '通知',
    value: 'Notice',
  },
  {
    key: '是否确认删除选中的通知',
    value: 'Are you sure you want to delete the selected notification',
  },
  {
    key: '免手续费-1次',
    value: 'Free of charge - once',
  },
  {
    key: '删除(购物车)',
    value: 'Delete',
  },
  {
    key: '不能全由空格组成',
    value: 'It cannot be all Spaces',
  },
  {
    key: '删除提示',
    value: 'Delete prompt',
  },
  {
    key: '提交失败',
    value: 'Submission Failed',
  },
  {
    key: '提现成功',
    value: 'Withdrawal success',
  },
  {
    key: '国际运费模拟',
    value: 'International freight simulation',
  },
  {
    key: '估计物流成本',
    value: 'Estimated logistics cost',
  },
  {
    key: '估计重量(KG)',
    value: 'Estimated weight (KG)',
  },
  {
    key: '预计商品总体积（m³）',
    value: 'Estimated total volume of goods (m³)',
  },
  {
    key: '重新输入',
    value: 'Re-enter',
  },
  {
    key: '计算',
    value: 'Calculate',
  },
  {
    key: '预计国际运费:',
    value: 'Expected international freight：',
  },
  {
    key: '预计总物流成本:',
    value: 'Estimated total logistics cost:',
  },
  {
    key: '税号/身份证号',
    value: 'NIF / DNI',
  },
  {
    key: '公司简介',
    value: 'Company introduction',
  },

  {
    key: '在美国的估计运费:',
    value: 'Estimated shipping costs in the United States：',
  },
  {
    key: '工作日上午 8:30 至下午 5:30',
    value: 'Working hour: Mon-Fri, 8:30 AM - 5:30 PM (CST)',
  },
  {
    key: '以客户为中心',
    value: 'Customer focus',
  },
  {
    key: '提出数量不能小于1',
    value: 'The quantity is not less than one',
  },
  {
    key: '请输入关键词、1688商品链接或通过图片搜索',
    value: 'Please enter keywords, 1688URL or search through product image',
  },
  {
    key: '公司',
    value: 'company',
  },

  {
    key: '法律条件',
    value: 'legal requirements',
  },
  {
    key: 'Cookie 政策',
    value: 'Cookie policy',
  },
  {
    key: '支持',
    value: 'Support',
  },
  {
    key: '免费注册',
    value: 'Free registration',
  },
  {
    key: '订单跟踪',
    value: 'Order tracking',
  },
  {
    key: '州',
    value: 'State',
  },
  {
    key: '信息和费率',
    value: 'Information and Rates',
  },
  {
    key: '费用和佣金',
    value: 'Fees and commissions',
  },
  {
    key: '请选择地区',
    value: 'Please select the region',
  },
  {
    key: '退货和退款',
    value: 'Returns and refunds',
  },
  {
    key: '会员服务(页脚)',
    value: 'Member Services (Footer)',
  },
  {
    key: '摄影服务(页脚)',
    value: 'Photography Service (footer)',
  },
  {
    key: '其他',
    value: 'Other',
  },
  {
    key: '成为Rakumart的会员',
    value: 'Become a Rakumart member',
  },
  {
    key: '搜索并支付产品的第一次付款',
    value: 'Search for and pay the first payment for the product',
  },
  {
    key: '质量控制和储存在我们的中国仓库',
    value: 'Quality control and storage in our China warehouse',
  },
  {
    key: '关于我们',
    value: 'About us',
  },
  {
    key: '国际',
    value: 'International',
  },
  {
    key: '第二次支付运费和国际航运',
    value: 'Second payment of freight and international shipping',
  },
  {
    key: '遵循运输过程并接收您的产品',
    value: 'Follow the shipping process and receive your product',
  },
  {
    key: '现在入住',
    value: 'Check in now',
  },
  {
    key: '推荐商品',
    value: 'Recommended Products',
  },
  {
    key: '给你的建议',
    value: 'Advice for you',
  },
  {
    key: '您的备注会同步展示给工作人员',
    value: 'Comments will be displayed to the seller at the same time.',
  },
  {
    key: '查看更多类别',
    value: 'See more categories',
  },
  {
    key: '特殊要求备注',
    value: 'Comments and remarks on special requirements',
  },
  {
    key: '*需求especiales',
    value: '*demand especiales',
  },
  {
    key:
      '您可以写下您对产品的任何服务和要求，例如定制包装盒产品定制等。我们的工作人员将会联系您并商讨细节',
    value:
      'Please write down any additional services or special requirements for your product, such as custom packaging or product customization. An experienced agent will contact you to discuss the details.',
  },
  {
    key: '*您的备注会同步展示给工作人员',
    value: 'Comments will be displayed to the seller at the same time.',
  },
  {
    key:
      '我们根据订单总额收取佣金。对于每个金额，都有特定百分比的佣金。请参阅下表，了解您的订单将低于多少百分比。',
    value:
      'We charge a commission on the total order. For each amount, there is a specified percentage commission. See the table below to find out what percentage your order will be below.',
  },
  {
    key: '全部费用',
    value: 'Total product expenditure',
  },
  {
    key: '产品费用细目',
    value: 'Breakdown of product costs',
  },
  {
    key: '佣金说明',
    value: 'Commission',
  },
  {
    key: '额外服务',
    value: 'additional service',
  },
  {
    key: '估计国际运费',
    value: 'Estimated international freight',
  },
  {
    key: '预计交货时间',
    value: 'Estimated delivery time',
  },
  {
    key: '估计总进口成本(包括物流和税收)的成本细目',
    value:
      'Cost breakdown of estimated total import costs including logistics and taxes',
  },
  {
    key: '估计国际运输',
    value: 'Estimate international transport',
  },
  {
    key: '对美国的估计',
    value: 'Estimates for the United States',
  },
  {
    key: '估计反倾销税',
    value: 'Estimated anti-dumping duty',
  },
  {
    key: '估计增值税',
    value: 'Estimated value added tax',
  },
  {
    key: '估计总开支',
    value: 'Estimated total expenditure',
  },
  {
    key: '为什么现在估计装运?',
    value: 'Why estimate the shipping cost now?',
  },
  {
    key:
      '为了提供最好的进口报价，一旦产品到达我们的仓库，就会计算出准确的运费和其他费用报价，所以现在你只支付产品。',
    value:
      'In order to provide the best import quotation, the exact shipping and other cost quotation is calculated once the product arrives at our warehouse, so now you only pay for the product.',
  },
  {
    key: '由于货物的分组和其他数据，我们可以提供一个更好的最终报价的运输。',
    value:
      'Due to the grouping of goods and other data, we can provide a better final quotation for shipping.',
  },
  {
    key:
      '这一细分包括产品的质量控制、合同规定的额外服务，以及在适当情况下合同规定的其他费用。',
    value:
      'This segment includes quality control of the product, additional services under the contract, and, where appropriate, other costs under the contract.',
  },
  {
    key: '选择运输类型以获得最终的运输报价',
    value: 'Estimated international shipping costs for different modes of transport',
  },
  {
    key: '推荐',
    value: 'Recommend',
  },
  {
    key: '已了解',
    value: 'Have to Understood',
  },
  {
    key: '已理解',
    value: 'Have to Understood',
  },
  {
    key: '订单细节确认',
    value: 'Confirmation of order details',
  },
  {
    key: '谢谢你。您的订单细节已正确确认。',
    value: 'Thank you Your order details have been confirmed correctly.',
  },
  {
    key: '邮箱',
    value: 'Email',
  },
  {
    key:
      '在24小时之内，您将收到一封电子邮件，里边包含该订单的国际运输方式和预估运输费用。',
    value:
      'In 24 hours, you will receive an email with the international shipping method and estimated shipping costs for the order.',
  },
  {
    key:
      '为了提供一个估计的运输报价，我们必须知道产品的尺寸和确切的重量。这就是为什么我们联系制造商，并计算您的订单的估计运输成本。',
    value:
      "In order to provide an estimated shipping quotation, we must know the dimensions and exact weight of the product. That's why we contact the manufacturer and calculate the estimated shipping cost of your order.",
  },
  {
    key: '订单摘要',
    value: 'Order Summary',
  },
  {
    key: '购买金额',
    value: 'amount of purchase',
  },
  {
    key: '后续怎么操作',
    value: 'What are the next steps?',
  },
  {
    key: '如何运作',
    value: 'How to operate',
  },
  {
    key: '我们联系制造商',
    value: 'We contact the manufacturer',
  },
  {
    key: '委员会',
    value: 'Handling fee ',
  },
  {},
  {
    key:
      '我们联系制造商确认产品购买费用，箱规尺寸等必要信息，从而预估产品的运输成本。',
    value:
      'We contact the manufacturer to confirm the cost of the product, the size of the box, and other necessary information to estimate the cost of shipping the product.',
  },
  {
    key: '您会在24小时内收到报价',
    value: 'You will receive the quotation within 24 hours',
  },
  {
    key: '这份报价中包括所有类型的预估国际运费（空运、海运和陆运）。',
    value:
      'Within 24 hours, we will provide you with an estimate of the shipping costs for all modes of transportation, including air, sea, and land.',
  },
  {
    key: '产品的付款',
    value: 'Pay for products',
  },
  {
    key: '我们将预估国际费用发送给您后，您就可以正式购买您的订单的产品了。',
    value:
      'Once you have reviewed and confirmed the estimated shipping costs, you can proceed with placing an order for the product.',
  },
  {
    key: '为什么现在估计装运费用?',
    value: 'Why estimate the shipping cost now?',
  },
  {
    key: '你有什么问题吗?',
    value: 'Do you have any questions?',
  },
  {
    key: '使用RAKU-chat与我们沟通',
    value: 'Use RAKU-chat to communicate with us',
  },
  {
    key: '使用WhatsApp与我们沟通',
    value: 'Chat to us via WhatsApp.',
  },
  {
    key: '银行转帐',
    value: 'Bank transfer',
  },
  {
    key: '把你的订单转到我们的银行账户。',
    value: 'Transfer your order to our bank account.',
  },
  {
    key: '订单正在处理中',
    value: 'The order is being processed',
  },
  {
    key: '请允许1至2个工作日处理订单。',
    value: 'Please allow 1 to 2 working days for order processing.',
  },
  {
    key: '我们进行购买',
    value: 'We make the purchase',
  },
  {
    key: '我们购买您的产品并将其送到我们的仓库。',
    value: 'We buy your products and send them to our warehouse.',
  },
  {
    key: '产品费用支付',
    value: 'Product payment',
  },
  {
    key: 'Envio仓库',
    value: 'Envio warehouse',
  },
  {
    key: '进口商联系人',
    value: 'Importer contact person',
  },
  {
    key: '付款方式',
    value: 'Terms of payment',
  },
  {
    key: '信用卡',
    value: 'The credit card',
  },
  {
    key: '接收银行信息',
    value: 'Receive bank information',
  },
  {
    key:
      '使用以下银行信息通过您的网上银行或移动应用程序进行转账。一旦订单被处理，就会进行购买。',
    value:
      'Use the following bank information to transfer money through your online banking or mobile app. Once the order is processed, the purchase is made.',
  },
  {
    key: '使用订单号作为付款概念/参考:',
    value: 'Use order number as payment concept/reference:',
  },
  {
    key: '收件人名称',
    value: 'Name of Recipient',
  },
  {
    key: 'SWIFT帐户',
    value: 'SWIFT account',
  },
  {
    key: '银行名称',
    value: 'Bank name',
  },
  {
    key: '进行付款',
    value: 'make a payment',
  },
  {
    key: 'Redsys CaixaBank支付网关',
    value: 'Redsys CaixaBank Payment gateway',
  },
  {
    key: '我已了解并同意该网站的隐私政策条',
    value:
      'I know and agree to the terms and conditions of this website‘s  Privacy Policy.',
  },
  {
    key: '我知道并同意本网站的',
    value: 'I know and agree to the terms and',
  },
  {
    key: '隐私政策条款和条件',
    value: "conditions of this website's Privacy Policy",
  },
  {
    key: '订单支付完成',
    value: 'Order completed successfully',
  },
  {
    key: '控制面板',
    value: 'The control panel',
  },
  {
    key: '修改数据',
    value: 'Modify the data',
  },
  {
    key: '接下来是什么?',
    value: "What's next?",
  },
  {
    key: '我们从工厂接收你们的产品',
    value: 'We receive your products from the factory',
  },
  {
    key: '购买您的产品的过程已经开始。',
    value: 'The process of purchasing your product has begun.',
  },
  {
    key: '我们期待在我们的仓库收到你们的产品。',
    value: 'We look forward to receiving your products in our warehouse.',
  },
  {
    key: '质量控制和处理',
    value: 'Quality control and handling',
  },
  {
    key:
      '检品人员在收到货物后会进行货物的质量检测并完成您的附加服务（如果您的订单有选择附加服务）',
    value:
      'We receive the product and start performing quality control and additional services (if necessary).',
  },
  {
    key: '运费和运输报价',
    value: 'Freight and shipping quotation',
  },
  {
    key:
      '我们将会选择最合理的国际运输方式，并为您报价，在5-7天内装运完成您的货物',
    value:
      'We calculate the best freight quotation and ship your goods within 5-6 days.',
  },
  {
    key: '运费支付',
    value: 'Payment of freight',
  },
  {
    key: '付款后，您的货物将被存放在rakumart中国仓库。',
    value:
      'After payment, your goods will be stored in the Rakumart China warehouse.',
  },
  {
    key: '修改收货地址',
    value: 'Modify shipping address',
  },
  {
    key: '城市',
    value: 'City',
  },
  {
    key: '公司(可选)',
    value: 'Company (optional)',
  },
  {
    key: '国际运输的最终报价',
    value: 'Final offer for international shipping',
  },
  {
    key: '进口总成本细目(包括物流和税收)',
    value: 'Breakdown of total import costs (including logistics and taxes)',
  },
  {
    key: '国际运输',
    // value: 'International transport',
    value:'In Transit'
  },
  {
    key: '应付总额',
    value: 'Total payabe amount',
  },
  {
    key: '国际航运付款',
    value: 'International shipping payment',
  },
  {
    key: '进口',
    value: 'import',
  },
  {
    key: '请联系客服修改配送单地址',
    value: 'Please contact customer service to modify the delivery address',
  },
  {
    key: '替换商品',
    value: 'Replace the goods',
  },
  {
    key: '库存不足商品',
    value: 'Goods out of stock',
  },
  {
    key: 'Raku消息',
    value: 'Raku message',
  },
  {
    key: '库存数量不足',
    value: 'Insufficient stock quantity',
  },
  {
    key: '可选择替换的商品',
    value: 'Optional replacement items',
  },
  {
    key: 'Rakumart帮助您从中国进口货物',
    value: 'Rakumart, we help you import from China',
  },
  {
    key:
      'Rakumart让您轻松获得从中国进口的“一站式”服务，包括工厂采购、质量检验、附加服务和国际航运。',
    value:
      'Rakumart gives you easy access to a "one-stop" service for imports from China, including factory purchasing, quality inspection, additional services and international shipping.',
  },
  {
    key: '全面、便捷的中国进口管理。',
    value: 'Comprehensive and convenient Chinese import management.',
  },
  {
    key: '待报价订单',
    value: 'Order to be quoted',
  },
  {
    key: '以您为中心',
    value: 'Focus on your career',
  },
  {
    key: '我们为您购买产品并优化所有的物流成本',
    value: 'We import and optimize all logistics costs for you',
  },
  {
    key: '完整的物流服务',
    value: 'Complete factory logistics',
  },
  {
    key: '后勤服务',
    value: 'Logistics services',
  },
  {
    key: '公平和有价值的佣金为您的业务',
    value: 'Fair and valuable commission for your business',
  },
  {
    key: '消费越多，等级越高，佣金越少',
    value: 'More spending, less commission',
  },
  {
    key: '佣金说明',
    value: 'The transaction',
  },
  {
    key: '为所有的产品提供附加服务',
    value: 'Provide additional services for all product requirements',
  },
  {
    key: '其他服务和完整解决方案',
    value: 'Services and overall solutions',
  },
  {
    key: '购买中',
    value: 'In the purchase',
  },
  {
    key: '请至少选择一项物流方式',
    value: 'Please select at least one logistics method',
  },
  {
    key: '国际费用明细',
    value: 'Other',
  },
  {
    key: '特价商品',
    value: 'bargain',
  },
  {
    key: '请将您的个人信息填入下方，所有字段都是必填字段。',
    value:
      'Please fill in the following fields with your information. All fields are required.',
  },
  {
    key: '操作',
    value: 'Operation',
  },
  {
    key: '临时保存',
    value: 'Temporary save',
  },
  {
    key: '追加',
    value: 'Add',
  },
  {
    key: '等待财务确认',
    value: 'Waiting for financial confirmation',
    },
    {
      key: '支付中',
      value: 'Pending ',
    },
  {
    key:
      '*由于供应商活动取消等原因，商品实际的价格和rakumart平台展现的价格可能会有出入。我们将在订单报价时将最终确认的价格提交给您。',
    value:
      'As prices may be affected due to supplier promotions or other reasons. After confirming inventory and other information, we will quote you based on the actual unit price of the product.',
  },
  {
    key: '搜索图片',
    value: 'Search for images',
  },
  {
    key: '竖版',
    value: 'Vertical version',
  },
  {
    key: '横版',
    value: 'Horizontal version',
  },
  {
    key: '今日人民币与美元兑换汇率',
    value: 'Exchange Rate',
  },
  {
    key: '附加服务费用',
    value: 'Additional services',
  },
  {
    key: '用户电子邮件',
    value: 'User Email',
  },

  {
    key: '很高兴你能来',
    value: 'Glad you could make it',
  },

  {
    key: '发送时间',
    value: 'Sending time',
  },
  {
    key: '谢谢，你的订单号',
    value: '谢谢，你的订单号',
  },

  {
    key: '谢谢。 关于您的订单号',
    value: '谢谢。 关于您的订单号',
  },
  // 国际物流
  {
    key: '分区',
    value: 'Location',
  },
  {
    key: '21+',
    value: '21KG+',
  },
  {
    key: '71+',
    value: '71KG+',
  },
  {
    key: '100+',
    value: '100KG+',
  },
  {
    key: '美西8.9',
    value: 'The Western United States 8.9',
  },
  {
    key: '32',
    value: '￥32',
  },
  {
    key: '31',
    value: '￥31',
  },
  {
    key: '29',
    value: '￥29',
  },
  {
    key: '美中4.5.6.7',
    value: 'The Central United States 4.5.6.7',
  },
  {
    key: '33',
    value: '￥33',
  },
  {
    key: '32',
    value: '￥32',
  },
  {
    key: '30',
    value: '￥30',
  },
  {
    key: '美东0.1.2.3',
    value: 'The Eastern United States 0.1.2.3',
  },
  {
    key: '34',
    value: '￥34',
  },
  {
    key: '33',
    value: '￥33',
  },
  {
    key: '31',
    value: '￥31',
  },
  {
    key: '时效',
    value: 'Delivery time',
  },
  {
    key: '9-14天到客户手上',
    value: '9-14 days',
  },
  {
    key: '25-30天到客户手上',
    value: '25-30 days',
  },
  {
    key: '美国国内派送方式',
    value: 'US domestic delivery method',
  },
  {
    key: 'UPS派送',
    value: 'UPS ',
  },
  {
    key: '非亚马逊地址，整票计费价格+1元/KG	',
    value: 'For non-Amazon addresses, chargeable price + CNY1.00/KG',
  },
  {
    key: '包裹重量要求',
    value: 'Parcel weight requirements',
  },
  {
    key: '单箱计费重少于12KG的,按照12KG算,单箱实重大于等于22.5KG加收170元/件。',
    value:
      ' If the chargeable weight of a parcel is less than 12KG, it will be calculated as 12KG. If the actual weight of a parcel is greater than or equal to 22.5KG, an extra CNY 170.00/parcel will be charged.',
  },
  {
    key:
      '单件包裹任何一边长超过120CM，收取超长费用120CNY/件，第二边超过75CM，同样收取120CNY/件,',
    value:
      ' If the length of any one side of a parcel exceeds 120CM, an extra length fee of CNY120.00/parcel will be charged; if the second side exceeds 75CM, a charge of CNY 120.00/parcel will also be charged',
  },
  {
    key: '单边超150CM，不能发',
    value: ' If any side exceeds 150CM, the parcel cannot be shipped',
  },
  {
    key: '单件包裹长+（宽+高）*2，如果超过266CM收取超长费用120CNY/件',
    value:
      ' Length + (width + height) * 2 of a parcel, if the total exceeds 266CM, an extra length fee of CNY120.00/parcel will be charged;',
  },
  {
    key: '如果超过330CM，不能发',
    value: 'if it exceeds 330CM, the parcel cannot be shipped',
  },
  {
    key: '单件包裹实重大于40KG，不能发',
    value:
      'If the actual weight of a parcel is greater than 40KG, it cannot be shipped',
  },
  {
    key: '附加费',
    value: 'Additional fees',
  },
  {
    key: '包包，服装，鞋类、汽车配件、摩托车配件，整票计费价格+1元/KG',
    value:
      'For bags, clothing, footwear, auto parts, motorcycle parts, the chargeable price + CNY1.00/KG',
  },
  {
    key: '餐具需提供FDA证书，整票计费价格+1/KG',
    value:
      'For tableware requiring an FDA certificate, the chargeable price + CNY1.00/KG',
  },
  {
    key: '眼镜，整票计费价格+2/KG',
    value: 'For glasses, the chargeable price + CNY2.00/KG',
  },
  {
    key: '手表附加费+5/支',
    value: 'For watches, surcharge +CNY5.00/piece',
  },
  {
    key: '每票默认5个品名，每多加1个品名，加收30元',
    value:
      'There are 5 product names per parcel by default; an additional CNY30.00 will be charged for each additional product name',
  },
  {
    key: '偏远地区收费标准',
    value: 'Charges for remote areas',
  },
  {
    key: '偏远地址20元/箱，超偏远地址30元/箱',
    value:
      'Remote areas CNY20.00 per parcel, the most remote areas CNY30.00 per parcel',
  },
  {
    key: '限制发送商品',
    value: 'Restricted shipping items',
  },
  {
    key:
      '不接仿牌、纯电池、贵金属、化工品、敏感品、儿童玩具类、违禁品、易燃易爆等明确规定的商品',
    value:
      'Counterfeit products, pure batteries, precious metals, chemicals, sensitive products, children"s toys, prohibited goods, flammable and explosive products, etc. are not accepted',
  },

  {
    key: '预计进口产品费用明细',
    value: 'Estimated product import details',
  },
  {
    key: '容积约为',
    value: 'The volume is about (m ³）',
  },
  {
    key: '立方米',
    value: 'cubic meter',
  },
  {
    key: '产品费用',
    value: ' Product value',
  },
  {
    key: '附加服务',
    value: 'Additional services',
  },
  {
    key: '中国物流费用',
    value: ' China domestic shipping',
  },
  {
    key: '不含运费的产品总成本',
    value: 'Total product cost excluding freight',
  },
  {
    key: '特拉华州',
    value: '',
  },
  {
    key: '  产品成本',
    value: 'Product cost',
  },
  {
    key: '  预估国际运费 ',
    value: ' Estimated international shipping costs',
  },
  {
    key: '  预估美国国内运费  ',
    value: 'Estimated domestic freight in the United States',
  },
  {
    key: '包含运费产品总成本 ',
    value: ' Total product cost including freig',
  },
  {
    key:
      '已生成正确，并且已经在发送到我们仓库的过程中。 我们已经向您发送了一封包含详细信息的电子邮件。请按照个人中心中的状态进行操作。',
    value:
      ' has been generated correctly and is in the process of sending to our warehouse. We have sent you an email with details. Please follow the status in the personal center.',
  },
  {
    key:
      '的发送：您已正确付款并且您的货物已在发送至我们在巴塞罗那的仓库。 商品到货时将提供国家快递（GLS）的追踪号码。您可以在个人中心中查看状态。',
    value:
      '的发送：您已正确付款并且您的货物已在发送至我们在巴塞罗那的仓库。 商品到货时将提供国家快递（GLS）的追踪号码。您可以在个人中心中查看状态。',
  },

  {
    key: '成为rakumart的一员',
    value: 'Become a member of rakumart',
  },
  {
    key: '现在注册',
    value: 'Register now',
  },
  {
    key: '搜索并下单您感兴趣的产品',
    value: 'Search and make the first payment for the products',
  },
  {
    key: '中国仓库将存储并为您检查货物',
    value: 'Quality control and storage management in Chinese warehouses',
  },
  {
    key: '预估总费用',
    value: 'Estimated total cost',
  },
  {
    key: '您需要支付国际运输的费用',
    value: 'Second payment for international freight and shipping',
  },
  {
    key: '特色类别',
    value: 'Characteristic category',
  },
  {
    key: '费用相关',
    value: 'Cost correlation',
  },
  {
    key: '美国运输中的订单',
    value: 'U.S. Transportation',
  },
  {
    key: '购买完了',
    value: 'Purchase complete',
  },
  {
    key: '没有调整',
    value: 'No adjustment',
  },
  {
    key: '实际购买数量',
    value: 'Actual quantity purchased',
  },
  {
    key: '提现',
    value: 'Withdrawal',
  },
  {
    key: '包含运费产品总成本  ',
    value: 'Total product cost including freight',
  },

  {
    key: '请在',
    value: 'Please resend ',
  },
  {
    key: '电子邮箱验证',
    // value: 'Email address authentication',
    value:'Email Verification'
  },
  {
    key: '手机号码验证',
    value: 'Mobile phone number authentication',
  },
  {
    key: '秒后重新获取验证码',
    value: 'the verification code after xx seconds',
  },
  {
    key: '订单取消日期',
    value: 'Order cancellation date',
  },
  {
    key: '修改',
    value: 'Change',
  },
  {
    key: '配送单详情',
    value: 'Shipping order details',
  },
  {
    key: '计费重量',
    value: ' Billable weight',
  },
  {
    key: '已完成',
    value: 'Have  complete',
  },

  {
    key: '订单完成',
    value: 'Complete',
  },

  {
    key: '服务条款',
    value: 'Terms of Service',
  },
  {
    key: ' 服务条款',
    value: 'Terms of service',
  },
  {
    key: '隐私政策',
    value: 'Privacy policy',
  },
  {
    key:
      '我们将会选择最合理的国际运输方式，并为您报价，在5-7天内装运完成您的货物-7天内装运你的货物。',
    value:
      'We calculate the best freight quotation and ship your goods within 5-7 days.',
  },
  {
    key: '国际航运的最终报价',
    value: 'Final offer for international shipping',
  },
  {
    key: '应付款金额',
    value: 'Amount payable',
  },
  {
    key: '付款后余额',
    value: 'Balance after payment',
  },
  {
    key: '去支付',
    value: 'Confirmation payment',
  },
  {
    key: '在线支付',
    value: 'Online payment',
  },
  {
    key: '返回',
    value: 'Return',
  },
  {
    key: '电子邮件/手机号码',
    value: 'Email/Mobile phone number',
  },
  {
    key: '未查找到该账号，请核对后再尝试或联系客服处理',
    value:
      'This account is not found, please try or contact the salesman processing after checking',
  },
  {
    key: '谢谢，关于您的订单号',
    value: 'Thank you. About the delivery of your order number',
  },
  {
    key: '随着你的购买量的增加，你必须支付的佣金率将逐渐降低。',
    value:
      'As your purchase volume increases, the commission rate that you have to pay will gradually decrease.',
  },
  {
    key: '我们将根据订单中货物的价值收取佣金。',
    value:
      'We will charge a commission based on the value of the goods in the order.',
  },
  {
    key: '佣金率是根据您购买的商品的累计价值确定的。（货物价值=数量*单价）',
    value:
      'The commission rate is determined based on the accumulated value of goods you have purchased. (Value of goods = Quantity * Unit Price)',
  },
  {
    key:
      '例如，如果您购买的商品的累计价值超过10000美元，您未来订单的佣金将变为9%。',
    value:
      'For example, if your the accumulated value of goods purchased exceeds $10000, the commission fee for your future orders will change to 9%.',
  },
  {
    key: '请参阅下表，查看您购买商品的累计价值所对应的百分比：',
    value:
      'Please refer to the table below to see the percentage corresponding to your accumulated value of goods purchased:',
  },
  {
    key: '谢谢，关于您的配送单号',
    value: 'Thank you. Thank you.About the delivery of your order number',
  },
  {
    key: '收件地址',
    value: 'Shipping Address',
  },

  {
    key: '配送单费用支付',
    value: 'Delivery order payment',
  },
  {
    key: '请输入旧登录密码',
    value: 'Please enter the old login password',
  },

  {
    key: '您可以查询订单进程，最后货物送达到你的手中',
    value: 'Follow the transportation process and receive your product',
  },
  {
    key: '可用余额',
    value: 'Available balance',
  },
  {
    key: 'paypal付款的情况下，还需要4%的手续费，请事先谅解',
    value:
      'If you use PayPal to pay, you will need to pay a 4% handling fee, please understand.',
  },
  {
    key: '是否使用PayPal支付？',
    value: '.Do you use PayPal to pay?',
  },
  {
    key: '请输入充值金额，使其超过估价总额',
    value:
      'Please enter the recharge amount and it needs to exceed the total estimated value',
  },
  {
    key: '支付失败',
    value: 'Payment failed',
  },
  {
    key: '对于给您带来的不便，我们深表歉意，但请重新操作。',
    value:
      'We apologize for the inconvenience caused to you, but please try again.',
    },
    {
      key: '价格明细',
      value:"Estimated single product cost [DDP]",
  },
  {
    key: '手续费',
    value: 'Service Fee',
    },
    {
      key: '是否取消当前订单?',
      value:'Do you want to cancel the current order?',

    }, {
      key: '例如，如果您购买的商品的累计价值超过50000美元，您未来订单的佣金将变为7%。',
      value:'For example, if your the accumulated value of goods purchased exceeds $10,000, the commission for your subsequent orders will change to 7%.',
    },
    {
      key: '月销量',
      value:"Monthly sales",
    }, {
      key: '复购率',
      value:'Repurchase rate',
    },
    {
      key: '银行地址',
      value:'Bank Address',
    },
    {
      key: "账号名称",
  value:'Account Name',    
    },
    {
      key: '复制成功',
      value:'Copy successfully',
    },
    {
      key: '综合排序',
      value:'Comprehensive ranking',
    },
    {
      key: '月贩卖数',
      value:'Monthly sales quantity',
    },
    {
      key: '价格',
      value:'Price',
    },
    {
      key: '最低价格',
      value:'Lowest price',
    },
    {
      key: '最高价格',
      value:'Highest price',
    },
    {
      key: '5星',
      value:'5 star',
    },
    {
      key: '4.5星~5星',
      value:'4.5 star to 5 star',
    },
    {
      key: '4星~4.5星',
      value:'4 star to 4.5 star',
    },
    {
      key: '1688精选商品',
      value:'1688 selected products',
    },
    {
      key: '1688精选工厂',
      value:'1688 selected factories',
    },
    {
      key: '店铺点评',
      value:'Shop reviews',
    },
    {
      key: '严选',
      value:'High-quality products',
    },
    {
      key: '优势',
      value:'Advantages',
    },
    {
      key: '当日出货',
      value:'Same-day shipping',
    },
    {
      key: '24小时以内出货',
      value:'Ship within 24 hours',
    },
    {
      key: '请完善商品信息',
      value:'Please complete the product information',
    },
    
    {
      key: '48小时以内出货',
      value:'Ship within 48 hours',
    },
    {
      key: '7天无理由退换商品',
      value:'7-day unconditional return and exchange policy',
    },
    {
      key: '1件起订',
      value:'Minimum order quantity of one unit',
    },
    {
      key: '历史记录',
      value:'Records',
    },
    {
      key: '依然支付',
      value: 'Continue with payment',
      
    },
    {
      key: '收货地址不完整，请查收',
      value: 'The Shipping address information is not complete, please check.',
    }, {
      key: '返回查看地址',
      value:'Back to Shipping address',
    },
    {
      key: '讲师宣传',
      value:'Promoter',
    },

    {
      key: '讲师类型',
      value:'Promoter',
    },
    {
      key: '讲师',
      value:'Promoter',
    },
    {
      key: '收起',
      value:'PutAway',
    },
    {
      key: '展开',
      value:'Expand',
    },
    {
      key: '如果您有任何问题，请通过 RakuChat 联系专员.',
      value:'If you have any questions,please contact the special agent through RakuChat.',
    }, {
      key: '业务备注回复',
      value:'Remark from agent',
    
    }, {
      key: '提出数量',
      value:'Proposed quantity',
    }, {
      key: '调查数量',
      value:'Proposed quantity',
    },
    {
      key: '中国费用',
      value:'Total cost in China',
    }, {
      key: '无附加服务',
      value:'No additional services',
    }, {
      key: '产品不可用',
      value:'Product unavailable',
    }, {
      key: '装箱单',
      value:'Packing list',
    },
    {
      key: '请输入装箱单号',
      value:'Please enter packinglist number',
    }, {
      key: '装箱单',
      value:'Packinglist',
    }, {
      key: '国际运输费用',
      value:'International transportation costs',
    }, {
      key: '发货信息',
      value:'Shipping information',
    },
    {
      key: '装箱单备注',
      value:'Remarks of packinglist',
    }, {
      key: '国际物流成本',
      value:'International logistics costs',
    },
    {
      key: '美国国内物流成本',
      value:'Domestic logistics costs in US',
    },
    {
      key: '其他费用',
      value:'Other fees',
    },
    {
      key: '汇率',
      value:'Exchange Rate',
    },
    {
      key: '总成本',
      value:'Total cost',
    },
    {
      key: '支付',
      value:'pay',
    },
    {
      key: '包括以下订单中的产品',
      value:"Include the products from the following orders",
    }, {
      key: "运单号",
      value:'Tracking number',
    }, {
      key:'物流方式',
      value:"Shipping method",
    }, {
      key: '您可以点击运单号查看实时物流信息',
      value:'You can click on the tracking number to view real-time shipping information ',
    },
    {
      key: '跟踪状态',
      value:"Tracking Status",
    },
    {
      key: 'Rakumart已经发货',
      value:'Rakumart has shipped',
    },
    {
      key: "中转",
      value:'In transit',
    },
    {
      key: '美国国内派送',
      value:'US domestic shipping',
    }, {
      key: "交付",
      value:"Delivered",
    }, {
      key: "装箱单详细信息",
      value:"Packinglist details",
    }, {
      key: "产品英文名称",
      value:"Product's English name",
    }, {
      key: '小计',
      value:'Subtotal',
    },{
      key:'包装数量',
      value:"Packing quantity",
    },
    {
      key: "正式提交",
      value:"Formal submission",
    }, {
      key: '业务已翻译待装箱',
      value:'Translated to be packed',
    },
    {
      key: '已装箱完成待报价',
      value:'Packaged and awaiting quotation',
    },
 
    {
      key: '已支付待确认 ',
      value:'Paid yet to be confirmed',
    },
    {
      key: '已支付已确认',
      value:'Paid and confirmed',
    },
    {
      key: '已订舱待回复',
      value:'Booking pending reply',
    },
    {
      key: '待装柜',
      value:'Pending container loading',
    },
    {
      key: '待出货',
      value:'Pending shipment',
    },
    {
      key: '已发出',
      value:'Sent out',
    },
    {
      key: '已通关',
      value:'Passed customs',
    },
    {
      key: "异常",
      value:'abnormal'
      
    }, {
      key: '下载包装清单',
      value:'Download packing list',
    }, {
      key: '提示：如果您想修改装箱单，请联系我们。',
      value:'Prompt：If you want to modify the packing list, please contact us.',
    }, {
      key:'点击此处展开或折叠所有部分',
      value:"Click here to expand or collapse all boxes",
    },
    {
      key: 'Rakumart装箱单单号',
      value:'Rakumart Packinglist number',
    },
    {
      key: "装有相同货物的箱子数量",
      value:'The number of boxes with the same goods',
    }, {
      key: "订单详情",
    value:'Order Details',
    },
    {
      key: "资金详情",
      value:'Financial details',
    },
    {
      key: "清除",
      value:'Clear',
    },
    {
      key: '电子邮箱认证',
      value:'Email Verification',
    },
    {
      key: "物流价格",
      value:'Logistic Price',
    }, {
      key: '问题商品明细',
      value:"Faulty Product Details",
    }, {
      key: 'Ctrl+V 将图片粘贴到搜索栏中',
      value:'Ctrl+V pastes the image into the search bar',
    },
    {
      key: '图片加载失败',
      value:'Image failed to load',
    },
    {
      key: '文件读取失败',
      value:'Failed to read the file',
    },
    {
      key: '图片压缩失败',
      value:'Image compression failed',
    
    },
    {
      key: '请上传 image/jpeg, image/png, image/jpg 格式的图片',
      value:'Please upload an image in JPEG, PNG, or JPG format',
    },
    {
      key: '一次最多上传六张图片',
      value:'You can upload up to six images at a time',
    }, {
      key: '图片大小不得超过50MB',
      value:'The image size must not exceed 50MB',
    }, {
      key: "请上传jpeg,png,jpg格式的图片",
      value:'Please upload images in JPEG, PNG, or JPG format',
    }, {
      key: '上传过程中发生错误',
      value:'An error occurred during the upload',
    }, {
      key: '上传图片',
      value:'Upload images',
    }, {
      key: '多选',
      value:'Multi-select',
    }, {
      key: '清空并重新上传',
      value:'Clear and re-upload',
    }, {
      key: '点击切换图像,可以对图像进行多个上传',
      value:'Click to switch images to upload multiple images',
    }, {
      key: '出货完了',
      value:'Shipment completed',
    }, {
      key: '已删除订单',
      value:'Deleted order',
    }, {
      key: '订单正式提交',
      value:'Order Formal Submission',
    },
    {
      key: '业务已翻译待确认',
      value:'Translated, pending confirmation',
    },{
      key: '采购已确认',
      value:'Procurement confirmed',
    },
    {
      key: '已报价',
      value:'Quoted',
    },
    {
      key: '采购已购买完成',
      value:'Purchase completed',
    },
    {
      key: '上架完成',
      value:'Shelving completed'
    },
]
function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {x
    return false
  }
  return true
}
let chongFu = []
let fanyi = (value) => {
  for (let i = 0; i < langs.length; i++) {
    if (langs[i].key == value) {
      // 把所有首字母转为大写.trim().toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())

      if (store.state.languagetype == 'English') {
        return langs[i].value
      } else if (store.state.languagetype == 'Chinese') {
        return langs[i].key
      }
    }
  }

  if (chongFu.indexOf(value) == -1) {
  }
  return value
}

export default fanyi
